import { inject } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { exhaustMap, take, map, catchError, of, share } from 'rxjs';

import { ActionItemsService } from 'src/app/core/services/action-items.service';
import {
  ActionItemsAppActions,
  ActionItemsAPIActions,
} from './action-items.actions';
import { handleError } from 'src/app/utils/error-handler.util';

export const getActionItems$ = createEffect(
  (
    actions$ = inject(Actions),
    actionItemsService = inject(ActionItemsService),
  ) => {
    return actions$.pipe(
      ofType(ActionItemsAppActions.getActionItems),
      exhaustMap(() =>
        actionItemsService.getActionItems().pipe(
          take(1),
          map(({ actionItems }) => {
            return ActionItemsAPIActions.getActionItemsSuccess({ actionItems });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(ActionItemsAPIActions.getActionItemsFailure({ message }));
          }),
          share(),
        ),
      ),
    );
  },
  { functional: true },
);

export const getActionItemById$ = createEffect(
  (
    actions$ = inject(Actions),
    actionItemsService = inject(ActionItemsService),
  ) => {
    return actions$.pipe(
      ofType(ActionItemsAppActions.getActionItemById),
      exhaustMap(({ id }) =>
        actionItemsService.getActionItemsById(id).pipe(
          take(1),
          map(({ actionItem }) => {
            return ActionItemsAPIActions.getActionItemByIdSuccess({
              actionItem,
            });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(
              ActionItemsAPIActions.getActionItemByIdFailure({ message }),
            );
          }),
          share(),
        ),
      ),
    );
  },
  { functional: true },
);

export const createActionItem$ = createEffect(
  (
    actions$ = inject(Actions),
    qestionsService = inject(ActionItemsService),
  ) => {
    return actions$.pipe(
      ofType(ActionItemsAppActions.createActionItem),
      exhaustMap(({ actionItem }) =>
        qestionsService.createActionItem(actionItem).pipe(
          take(1),
          map(({ actionItem }) => {
            return ActionItemsAPIActions.createActionItemSuccess({
              actionItem,
            });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(
              ActionItemsAPIActions.createActionItemFailure({
                message,
              }),
            );
          }),
          share(),
        ),
      ),
    );
  },
  { functional: true },
);

export const updateActionItem$ = createEffect(
  (
    actions$ = inject(Actions),
    qestionsService = inject(ActionItemsService),
  ) => {
    return actions$.pipe(
      ofType(ActionItemsAppActions.updateActionItem),
      exhaustMap(({ actionItem }) =>
        qestionsService.updateActionItem(actionItem).pipe(
          take(1),
          map(({ actionItem }) => {
            return ActionItemsAPIActions.updateActionItemSuccess({
              actionItem,
            });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(
              ActionItemsAPIActions.updateActionItemFailure({
                message,
              }),
            );
          }),
          share(),
        ),
      ),
    );
  },
  { functional: true },
);

export const removeOrganization$ = createEffect(
  (
    actions$ = inject(Actions),
    qestionsService = inject(ActionItemsService),
  ) => {
    return actions$.pipe(
      ofType(ActionItemsAppActions.removeActionItem),
      exhaustMap(({ id }) =>
        qestionsService.removeActionItem(id).pipe(
          take(1),
          map(({ actionItemId, message }) => {
            return ActionItemsAPIActions.removeActionItemSuccess({
              actionItemId,
              message,
            });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(
              ActionItemsAPIActions.removeActionItemFailure({
                message,
              }),
            );
          }),
          share(),
        ),
      ),
    );
  },
  { functional: true },
);

export const getActionItemsByQuestionId$ = createEffect(
  (
    actions$ = inject(Actions),
    actionItemsService = inject(ActionItemsService),
  ) => {
    return actions$.pipe(
      ofType(ActionItemsAppActions.getActionItemsByQuestionId),
      exhaustMap(({ questionId }) =>
        actionItemsService.getActionItemsByQuestionId(questionId).pipe(
          take(1),
          map(({ actionItems }) => {
            return ActionItemsAPIActions.getActionItemsByQuestionIdSuccess({
              actionItems,
            });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(
              ActionItemsAPIActions.getActionItemsByQuestionIdFailure({
                message,
              }),
            );
          }),
          share(),
        ),
      ),
    );
  },
  { functional: true },
);

export const updateActionItemControls$ = createEffect(
  (
    actions$ = inject(Actions),
    actionItemsService = inject(ActionItemsService),
  ) => {
    return actions$.pipe(
      ofType(ActionItemsAppActions.updateActionItemControls),

      exhaustMap(({ id, controls }) =>
        actionItemsService.updateActionItemControls(id, controls).pipe(
          take(1),
          map(({ actionItem }) => {
            return ActionItemsAPIActions.updateActionItemControlsSuccess({
              actionItem,
            });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(
              ActionItemsAPIActions.updateActionItemControlsFailure({
                message,
              }),
            );
          }),
          share(),
        ),
      ),
    );
  },
  {
    functional: true,
  },
);

export const updateActionItemServices$ = createEffect(
  (
    actions$ = inject(Actions),
    actionItemsService = inject(ActionItemsService),
  ) => {
    return actions$.pipe(
      ofType(ActionItemsAppActions.updateActionItemServices),
      exhaustMap(({ id, services }) =>
        actionItemsService.updateActionItemServices(id, services).pipe(
          take(1),
          map(({ actionItem }) =>
            ActionItemsAPIActions.updateActionItemServicesSuccess({
              actionItem,
            }),
          ),
          catchError((error) => {
            const message = handleError(error);
            return of(
              ActionItemsAPIActions.updateActionItemServicesFailure({
                message,
              }),
            );
          }),
          share(),
        ),
      ),
    );
  },
  {
    functional: true,
  },
);

export const uploadActionItemDocs$ = createEffect(
  (
    actions$ = inject(Actions),
    actionItemsService = inject(ActionItemsService),
  ) => {
    return actions$.pipe(
      ofType(ActionItemsAppActions.uploadActionItemDocs),
      exhaustMap(({ id, files }) =>
        actionItemsService.uploadActionItemDocs(id, files).pipe(
          take(1),
          map(({ actionItemId, docs }) =>
            ActionItemsAPIActions.uploadActionItemDocsSuccess({
              actionItemId,
              docs,
            }),
          ),
          catchError((error) => {
            const message = handleError(error);
            return of(
              ActionItemsAPIActions.uploadActionItemDocsFailure({ message }),
            );
          }),
        ),
      ),
      share(),
    );
  },
  {
    functional: true,
  },
);

export const removeActionItemDoc = createEffect(
  (
    actions$ = inject(Actions),
    actionItemsService = inject(ActionItemsService),
  ) => {
    return actions$.pipe(
      ofType(ActionItemsAppActions.removeActionItemDoc),
      exhaustMap(({ id, docId }) =>
        actionItemsService.removeActionItemDoc(id, docId).pipe(
          take(1),
          map(({ actionItemId, doc }) =>
            ActionItemsAPIActions.removeActionItemDocSuccess({
              actionItemId,
              doc,
            }),
          ),
          catchError((error) => {
            const message = handleError(error);
            return of(
              ActionItemsAPIActions.removeActionItemDocFailure({ message }),
            );
          }),
        ),
      ),
      share(),
    );
  },
  {
    functional: true,
  },
);
