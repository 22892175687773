<div
  class="flex flex-col p-5 border-l border-r border-b border-gray-300 rounded-b-2xl mb-2"
>
  <div class="flex justify-between px-5">
    <p class="text-lg font-bold text-gray-900 dark:text-gray-50" heading>
      {{ metadata.title }}
    </p>

    <button
      type="button"
      (click)="addRow()"
      class="block rounded-md bg-primary-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    >
      Add {{ metadata.entityName }}
    </button>
  </div>
  <table class="min-w-full divide-y divide-gray-600 dark:divide-gray-500">
    <thead>
      <tr>
        @for (col of columns$ | async; track col.key; let isFirst = $first) {
          <th
            scope="col"
            class="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
            [ngClass]="{ 'sm:pl-0': isFirst }"
          >
            {{ col.label }}
          </th>
        }
        <th scope="col" class="relative py-3.5 px-3 sm:pr-0">
          <span class="sr-only">Edit</span>
        </th>
      </tr>
    </thead>
    <tbody class="divide-y divide-gray-200 dark:divide-gray-600">
      @if ((data$ | async)?.length === 0) {
        <tr>
          <td
            [attr.colspan]="(columns$ | async)!.length + 2"
            class="text-center py-5"
          >
            <p class="text-gray-500 dark:text-gray-400">
              No {{ metadata.entityName }} found
            </p>
          </td>
        </tr>
      }
      @for (item of data$ | async; track item.id) {
        <tr
          [attr.data-row-id]="item._id"
          class="bg-white dark:bg-gray-800 hover:cursor-move active:cursor-grabbing transition-shadow"
        >
          @for (col of columns$ | async; track col.key; let isFirst = $first) {
            <td
              class="whitespace-nowrap py-5 pl-4 pr-3 text-sm"
              [ngClass]="{ 'sm:pl-0': isFirst }"
            >
              <!-- Read Mode cells -->
              @if (editRowId !== item._id) {
                @switch (col.type) {
                  @case ("text") {
                    <cap-text-cell [text]="item[col.key]"></cap-text-cell>
                  }
                  @case ("description") {
                    <cap-text-cell [text]="item[col.key]"></cap-text-cell>
                  }
                  @case ("radio") {
                    <cap-text-cell [text]="item[col.key]"></cap-text-cell>
                  }
                  @case ("list") {
                    <cap-text-cell
                      [text]="
                        col.key === 'weight'
                          ? convertWeight(item[col.key])
                          : item[col.key]
                      "
                    ></cap-text-cell>
                  }
                  @case ("button") {
                    <cap-edit-button-cell
                      (buttonClicked)="onButtonClicked(item._id, col.key)"
                      [label]="col.label"
                    ></cap-edit-button-cell>
                  }
                  @case ("status") {
                    <cap-status-cell [status]="item[col.key]"></cap-status-cell>
                  }
                  @case ("checkbox") {
                    <cap-status-cell
                      [status]="item[col.key]"
                      [props]="
                        col.options ?? [
                          { label: 'Positive', value: true },
                          { label: 'Negative', value: false }
                        ]
                      "
                    ></cap-status-cell>
                  }
                  @case ("toggle") {
                    <cap-edit-toggle-button-cell
                      (checkChange)="onToggelClicked($event, item._id, col.key)"
                      [button]="item[col.key]"
                    ></cap-edit-toggle-button-cell>
                  }
                  <!-- Other column types -->
                  @default {
                    <p></p>
                  }
                }
              }
              <!-- Edit Mode cells -->
              @if (editRowId === item._id) {
                @switch (col.type) {
                  @case ("text") {
                    <cap-edit-text-cell
                      [text]="item[col.key]"
                      (textChange)="
                        onCellValueChange($event, item._id, col.key)
                      "
                    ></cap-edit-text-cell>
                  }
                  @case ("description") {
                    <cap-edit-text-area-cell
                      [description]="item[col.key]"
                      (descriptionChange)="
                        onCellValueChange($event, item._id, col.key)
                      "
                    ></cap-edit-text-area-cell>
                  }
                  @case ("radio") {
                    <cap-edit-radio-cell
                      [options]="col.options!"
                      [selectedOption]="item[col.key]"
                      [groupName]="col.key"
                      (optionChange)="
                        onCellValueChange($event, item._id, col.key)
                      "
                    ></cap-edit-radio-cell>
                  }
                  @case ("list") {
                    <cap-edit-list-cell
                      [options]="col.options!"
                      [selectedOption]="item[col.key]"
                      (optionChange)="
                        onCellValueChange($event, item._id, col.key)
                      "
                    ></cap-edit-list-cell>
                  }
                  @case ("button") {
                    <cap-edit-button-cell
                      (buttonClicked)="onButtonClicked(item._id, col.key)"
                      [label]="col.label"
                      [disabled]="true"
                    ></cap-edit-button-cell>
                  }
                  @case ("status") {
                    <cap-edit-status-cell
                      [options]="col.options!"
                      [groupName]="col.key"
                      [selectedOption]="item[col.key]"
                      (optionChange)="
                        onCellValueChange($event, item._id, col.key)
                      "
                    ></cap-edit-status-cell>
                  }
                  @case ("checkbox") {
                    <cap-edit-checkbox-cell
                      [isChecked]="item[col.key]"
                      [groupName]="item._id + '-' + col.key"
                      [label]="'Is Positive'"
                      (checkChange)="
                        onCellValueChange($event, item._id, col.key)
                      "
                    ></cap-edit-checkbox-cell>
                  }
                  @case ("toggle") {
                    <cap-edit-toggle-button-cell
                      (checkChange)="onToggelClicked($event, item._id, col.key)"
                      [button]="item[col.key]"
                    ></cap-edit-toggle-button-cell>
                  }
                  <!-- Other column types -->
                  @default {
                    <p></p>
                  }
                }
              }
            </td>
          }
          <td
            class="relative flex justify-end items-center gap-x-4 whitespace-nowrap my-3 py-5 pl-3 pr-4 text-sm font-medium sm:pr-0"
          >
            <!--Action Buttons -->
            @if (editRowId !== item._id) {
              <button
                type="button"
                (click)="onUpdateClicked(item._id)"
                class="text-indigo-600 hover:text-indigo-800 dark:text-indigo-500 dark:hover:text-indigo-400"
              >
                Edit
              </button>
              <button
                type="button"
                (click)="onDeleteClicked(item)"
                class="text-red-600 hover:text-red-800 dark:text-red-500 dark:hover:text-red-400"
              >
                Remove
              </button>
            }

            <!-- Edit Mode Actions -->
            @if (editRowId === item._id) {
              <button
                type="button"
                (click)="confirmRowEdit()"
                class="rounded-full p-1 flex justify-center items-center bg-gray-100 hover:bg-green-500 dark:bg-gray-700 dark:hover:bg-green-500 text-gray-800 hover:text-gray-50 dark:text-gray-50 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
              >
                <span class="material-symbols-outlined"> check </span>
              </button>
              <button
                type="button"
                (click)="cancelRowEdit()"
                class="rounded-full p-1 flex justify-center bg-gray-100 hover:bg-primary-500 dark:bg-gray-700 dark:hover:bg-primary-500 text-gray-800 hover:text-gray-50 dark:text-gray-50 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
              >
                <span class="material-symbols-outlined"> close </span>
              </button>
            }
          </td>
        </tr>
      }
    </tbody>
  </table>
</div>
