import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { take, map, tap } from 'rxjs';

import { EditAccoridionSectionStore } from './edit-accordion-section.store';
import { StatusCellComponent } from '../../table/status-cell/status-cell.component';
import { TextCellComponent } from '../../table/text-cell/text-cell.component';
import { EditButtonCellComponent } from '../edit-button-cell/edit-button-cell.component';
import { EditCheckboxCellComponent } from '../edit-checkbox-cell/edit-checkbox-cell.component';
import { EditListCellComponent } from '../edit-list-cell/edit-list-cell.component';
import { EditRadioCellComponent } from '../edit-radio-cell/edit-radio-cell.component';
import { EditStatusCellComponent } from '../edit-status-cell/edit-status-cell.component';
import { EditTextCellComponent } from '../edit-text-cell/edit-text-cell.component';
import {
  IEditableColumn,
  IEditableTableMetadata,
} from 'src/app/core/models/column.model';
import { convertWeight } from 'src/app/utils/entity-formatting.util';
import {
  IActionItem,
  IActionItemBase,
} from 'src/app/core/models/action-items.model';
import { EditTextAreaCellComponent } from '../edit-text-area-cell/edit-text-area-cell.component';
import { EditToggleButtonCellComponent } from '../edit-toggle-button-cell/edit-toggle-button-cell.component';

@Component({
  selector: 'cap-edit-accordion-section',
  standalone: true,
  imports: [
    CommonModule,
    EditTextCellComponent,
    EditTextAreaCellComponent,
    EditButtonCellComponent,
    EditListCellComponent,
    EditRadioCellComponent,
    EditStatusCellComponent,
    EditCheckboxCellComponent,
    StatusCellComponent,
    TextCellComponent,
    EditToggleButtonCellComponent,
  ],
  providers: [EditAccoridionSectionStore],
  templateUrl: './edit-accordion-section.component.html',
  styleUrl: './edit-accordion-section.component.scss',
})
export class EditAccordionSectionComponent {
  editRowId: string | null = null;
  private originalData: { [key: string]: any } = {};

  @Output() onEntityDeleted: EventEmitter<IActionItem> =
    new EventEmitter<IActionItem>();

  @Output() onEntityCreated: EventEmitter<IActionItemBase> =
    new EventEmitter<IActionItemBase>();

  @Output() onEntityUpdated: EventEmitter<IActionItem> =
    new EventEmitter<IActionItem>();

  @Output() buttonClicked: EventEmitter<{
    entityId: string;
    columnKey: string;
  }> = new EventEmitter<{ entityId: string; columnKey: string }>();

  private readonly componentStore = inject(EditAccoridionSectionStore);

  @Input() set columns(value: IEditableColumn[]) {
    this.componentStore.setColumns(value);
  }

  @Input() metadata: IEditableTableMetadata = {
    title: '',
    entityName: '',
    accordion: false,
  };

  @Input() set data(value: any[]) {
    this.componentStore.setData$(value);
  }

  @Input() set totalItems(value: number) {
    this.componentStore.setTotalItems$(value);
  }

  onDeleteClicked(entity: IActionItem) {
    this.onEntityDeleted.emit(entity);
  }

  onUpdateClicked(entityId: string) {
    const currentItem = this.data$.pipe(
      take(1),
      map((data) => data.find((item) => item._id === entityId)),
    );

    currentItem.subscribe((item) => {
      this.originalData[entityId] = JSON.parse(JSON.stringify(item));
      this.editRowId = entityId;
    });
  }

  convertWeight(value: number) {
    return convertWeight(value);
  }

  // Events comming from edit cells

  onCellValueChange(
    newValue: string | number | boolean,
    entityId: string,
    columnKey: string,
  ): void {
    this.componentStore.updateDataItem({ entityId, columnKey, newValue });
  }

  onButtonClicked(entityId: string, columnKey: string) {
    this.buttonClicked.emit({ entityId, columnKey });
  }

  // Add Empty Row
  addRow() {
    const emptyItem = { _id: `temp-${Date.now()}` };
    this.componentStore.addData(emptyItem); // We'll implement addData in the store
    this.editRowId = emptyItem._id; // Enter edit mode for this new row immediately
  }

  // Row Edit Actions
  confirmRowEdit() {
    if (!this.editRowId) return;
    this.componentStore
      .getDataItem$(this.editRowId)
      .pipe(
        take(1),
        tap((editedItem) => {
          if (this.editRowId?.startsWith('temp')) {
            delete editedItem._id;
            this.onEntityCreated.emit(editedItem);
          } else {
            this.onEntityUpdated.emit(editedItem);
          }
        }),
      )
      .subscribe();

    this.editRowId = null;
  }

  cancelRowEdit() {
    if (this.editRowId?.startsWith('temp-')) {
      this.componentStore.removeDataItem(this.editRowId);
    } else if (this.originalData[this.editRowId!]) {
      for (let col in this.originalData[this.editRowId!]) {
        this.componentStore.updateDataItem({
          entityId: this.editRowId!,
          columnKey: col,
          newValue: this.originalData[this.editRowId!][col],
        });
      }

      delete this.originalData[this.editRowId!];
    }

    this.editRowId = null;
  }

  readonly columns$ = this.componentStore.columns$;
  readonly data$ = this.componentStore.data$;
  readonly totalItems$ = this.componentStore.totalItems$;

  onToggelClicked($event: any, _id: any, key: string) {
    this.onCellValueChange($event, _id, key);
    this.editRowId = _id;
  }
}
