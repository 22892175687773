@if (isLoading$ | async) {
  <div class="flex justify-center items-center w-full h-64">
    <p-progressSpinner class="w-16 h-16"></p-progressSpinner>
  </div>
} @else {
  <p-carousel
    [value]="(assessmentCategories$ | async) ?? []"
    [numVisible]="13"
    [numScroll]="1"
  >
    <ng-template let-item pTemplate="item">
      <div class="border-1 surface-border border-round px-1 py-2 sm:px-0">
        <div
          class="w-fit h-32 border-2 mr-1 rounded-2xl cursor-pointer transition-colors duration-300 ease-in-out hover:bg-gray-100 hover:border-white hover:shadow-default hover:scale-105"
          [ngClass]="{
            'scale-110':
              (currentSelectedCategory$ | async)?.category?._id ===
              item.category._id
          }"
          [ngStyle]="{
            'border-color': getScoreColor(item.assessment.score),
            color: getScoreColor(item.assessment.score),
            'box-shadow':
              item &&
              (currentSelectedCategory$ | async) &&
              item.category._id ===
                (currentSelectedCategory$ | async)?.category?._id
                ? '0 6px 10px -1px ' +
                  getScoreColor(item.assessment.score) +
                  ', 0 2px 4px -2px ' +
                  getScoreColor(item.assessment.score)
                : 'none'
          }"
          (click)="onCategorySelected(item)"
        >
          <div>
            <div class="relative mx-auto">
              <img
                [src]="
                  item.category.avatars[item.category.avatars.length - 1]
                    ? item.category.avatars[item.category.avatars.length - 1]
                        .url
                    : 'assets/images/empty-avatar.jpg'
                "
                [alt]="item.category.name"
                class="w-40 h-20 rounded-xl"
              />
              <p-knob
                [ngModel]="item.assessment.score.toFixed(0)"
                [size]="35"
                class="absolute bg-white dark:bg-slate-900 border-2 border-white rounded-full"
                readonly="true"
                [ngStyle]="{ 'left.px': 8, 'top.px': 5 }"
                [valueColor]="getScoreColor(item.assessment.score)"
              />
            </div>
          </div>
          <div class="flex justify-center w-full font-light text-xs px-1">
            <p class="dark:text-white text-center">
              {{
                item.category.name.length > 25
                  ? item.category.name.slice(0, 20) + "..."
                  : item.category.name
              }}
            </p>
          </div>
        </div>
      </div>
    </ng-template>
  </p-carousel>
}
