import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  CdkDropList,
  CdkDrag,
  CdkDragDrop,
  moveItemInArray,
  CdkDragStart,
  CdkDragRelease,
} from '@angular/cdk/drag-drop';
import {
  filter,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { Store } from '@ngrx/store';

import {
  IEditableColumn,
  IEditableTableMetadata,
} from 'src/app/core/models/column.model';
import { StatusCellComponent } from '../table/status-cell/status-cell.component';
import { TextCellComponent } from '../table/text-cell/text-cell.component';
import { EditableTableStore } from './editable-table.store';
import { EditButtonCellComponent } from './edit-button-cell/edit-button-cell.component';
import { EditListCellComponent } from './edit-list-cell/edit-list-cell.component';
import { EditRadioCellComponent } from './edit-radio-cell/edit-radio-cell.component';
import { EditStatusCellComponent } from './edit-status-cell/edit-status-cell.component';
import { EditTextCellComponent } from './edit-text-cell/edit-text-cell.component';
import {
  IQuestion,
  IQuestionBase,
  IQuestionOption,
} from 'src/app/core/models/questions.model';
import { convertWeight } from 'src/app/utils/entity-formatting.util';
import { EditCheckboxCellComponent } from 'src/app/shared/editable-table/edit-checkbox-cell/edit-checkbox-cell.component';
import { EditAccordionSectionComponent } from './edit-accordion-section/edit-accordion-section.component';
import {
  IActionItem,
  IActionItemBase,
} from 'src/app/core/models/action-items.model';
import BadgeCellComponent from '../table/badge-cell/badge-cell.component';
import { EditBadgeCellComponent } from './edit-badge-cell/edit-badge-cell.component';
import {
  IStandard,
  IStandardBase,
} from 'src/app/core/models/security-controls.model';
import { DateCellComponent } from '../table/date-cell/date-cell.component';
import {
  selectStandardById,
  selectStandardGroupings,
} from 'src/app/state/security-controls/standards.state';
import { selectServiceSourceById } from 'src/app/state/services/service-sources.state';
import { selectServiceVendorById } from 'src/app/state/services/service-vendors.state';
import { CostCellComponent } from '../table/cost-cell/cost-cell.component';
import { EditTextAreaCellComponent } from './edit-text-area-cell/edit-text-area-cell.component';
import { EditToggleButtonCellComponent } from './edit-toggle-button-cell/edit-toggle-button-cell.component';
import { validateUrl } from '../../utils/url.util';
import { AlertActions } from '../../state/alert/alert.actions';
import { CdkScrollable } from '@angular/cdk/overlay';
@Component({
  selector: 'cap-editable-table',
  standalone: true,
  imports: [
    CommonModule,
    EditTextCellComponent,
    EditTextAreaCellComponent,
    EditButtonCellComponent,
    EditListCellComponent,
    EditRadioCellComponent,
    EditStatusCellComponent,
    EditCheckboxCellComponent,
    StatusCellComponent,
    TextCellComponent,
    EditAccordionSectionComponent,
    BadgeCellComponent,
    EditBadgeCellComponent,
    DateCellComponent,
    CdkDropList,
    CdkDrag,
    CostCellComponent,
    EditToggleButtonCellComponent,
    CdkScrollable,
  ],
  providers: [EditableTableStore],
  templateUrl: './editable-table.component.html',
  styleUrl: './editable-table.component.scss',
})
export class EditableTableComponent implements OnInit {
  @Input() isDraggable = false;
  editRowId: string | null = null;
  expandedRowId: string | null = null;

  private store = inject(Store);

  private originalData: { [key: string]: any } = {};

  @Output() onEntityDeleted: EventEmitter<IQuestion | IQuestionOption> =
    new EventEmitter<IQuestion | IQuestionOption>();

  @Output() onEntityCreated: EventEmitter<
    IStandardBase | IQuestionBase | IQuestionOption
  > = new EventEmitter<IStandardBase | IQuestionBase | IQuestionOption>();

  @Output() onEntityUpdated: EventEmitter<
    IStandard | IQuestion | IQuestionOption
  > = new EventEmitter<IStandard | IQuestion | IQuestionOption>();

  @Output() onAccordionEntityDeleted: EventEmitter<IActionItem> =
    new EventEmitter<IActionItem>();

  @Output() onAccordionEntityCreated: EventEmitter<IActionItemBase> =
    new EventEmitter<IActionItemBase>();

  @Output() onAccordionEntityUpdated: EventEmitter<IActionItem> =
    new EventEmitter<IActionItem>();

  @Output() onAccordionButtonClicked: EventEmitter<string> =
    new EventEmitter<string>();

  @Output() buttonClicked: EventEmitter<{
    entityId: string;
    columnKey: string;
  }> = new EventEmitter<{ entityId: string; columnKey: string }>();

  @Output() onSortChanged = new EventEmitter<{
    entityId: string;
    previousIndex: number;
    currentIndex: number;
  }>();

  private readonly componentStore = inject(EditableTableStore);

  @Input() set columns(value: IEditableColumn[]) {
    this.componentStore.setColumns(value);
  }

  @Input() metadata: IEditableTableMetadata = {
    title: '',
    entityName: '',
    accordion: false,
  };

  @Input() accordionMetadata: IEditableTableMetadata = {
    title: '',
    entityName: '',
    accordion: false,
  };

  @Input() accordionColumns: IEditableColumn[] = [];

  @Input() accordionData$: Observable<any[]> = of([]);

  @Input() set data(value: any[]) {
    this.componentStore.setData$(value);
  }

  @Input() set totalItems(value: number) {
    this.componentStore.setTotalItems$(value);
  }

  onDeleteClicked(entity: IQuestion) {
    this.onEntityDeleted.emit(entity);
  }

  ngOnInit(): void {}

  getStandardSuggestedGroupings(standardId: string): void {
    if (!this.groupingsCache.has(standardId)) {
      const groupings$ = this.store
        .select(selectStandardGroupings(standardId))
        .pipe(
          map((groupings) => groupings ?? []),
          shareReplay(1), // Ensure the observable is hot and replayed for multiple subscribers
        );
      this.groupingsCache.set(standardId, groupings$);
    }
    this.suggestedStandardGroupings = this.groupingsCache.get(standardId)!;
  }

  onUpdateClicked(entityId: string) {
    const currentItem = this.data$.pipe(
      take(1),
      map((data) => data.find((item) => item._id === entityId)),
    );

    currentItem.subscribe((item) => {
      this.originalData[entityId] = JSON.parse(JSON.stringify(item));
      this.editRowId = entityId;

      if (this.metadata.entityName === 'Security Controls' && item.standardId) {
        this.getStandardSuggestedGroupings(item.standardId);
      }
    });
  }

  convertWeight(value: number) {
    return convertWeight(value);
  }

  private groupingsCache = new Map<string, Observable<string[]>>();

  suggestedStandardGroupings: Observable<string[]> = of([]);

  getEntityRowNameById(colName: string, itemId: string) {
    switch (colName) {
      case 'standardId': {
        return this.store
          .select(selectStandardById(itemId))
          .pipe(map((standard) => `${standard?.name} ${standard?.version}`));
        break;
      }
      case 'sourceId': {
        return this.store
          .select(selectServiceSourceById(itemId))
          .pipe(map((source) => source?.name));
        break;
      }
      case 'vendorId': {
        return this.store
          .select(selectServiceVendorById(itemId))
          .pipe(map((vendor) => vendor?.name));
      }
      default:
        return null;
    }
  }

  drop(event: CdkDragDrop<any[]>) {
    this.data$.pipe(take(1)).subscribe((data) => {
      if (event.previousIndex !== event.currentIndex) {
        this.onSortChanged.emit({
          entityId: data[event.previousIndex]?._id,
          previousIndex: event.previousIndex,
          currentIndex: event.currentIndex,
        });
      }
      moveItemInArray(data, event.previousIndex, event.currentIndex);
    });
  }

  isDragging = false;

  onDragStarted(event: CdkDragStart) {
    this.isDragging = true;
  }

  onDragReleased(event: CdkDragRelease) {
    this.isDragging = false;
  }

  // Events comming from edit cells

  onCellValueChange(
    newValue: string | number | boolean | string[],
    entityId: string,
    columnKey: string,
  ): void {
    if (
      this.metadata.entityName === 'Security Controls' &&
      columnKey === 'standardId'
    ) {
      this.getStandardSuggestedGroupings(<string>newValue);
    }
    if (
      this.metadata.entityName === 'Service' ||
      this.metadata.entityName === 'Service Vendor' ||
      this.metadata.entityName === 'Service Source'
    ) {
      if (columnKey === 'url') {
        if (!newValue.toString().includes('http')) {
          newValue = `https://${newValue}`;
        }
      }
    }
    this.componentStore.updateDataItem({ entityId, columnKey, newValue });
  }

  onButtonClicked(entityId: string, columnKey: string) {
    this.buttonClicked.emit({ entityId, columnKey });
  }
  onAccordionButtonsClicked(btnInfos: { entityId: string; columnKey: string }) {
    this.buttonClicked.emit(btnInfos);
  }

  // Add Empty Row
  addRow() {
    const emptyItem = { _id: `temp-${Date.now()}` };
    this.componentStore.addData(emptyItem); // We'll implement addData in the store
    this.editRowId = emptyItem._id; // Enter edit mode for this new row immediately
  }

  // Row Edit Actions
  confirmRowEdit() {
    let isValidData = true;
    if (!this.editRowId) return;

    this.componentStore
      .getDataItem$(this.editRowId)
      .pipe(
        take(1),
        tap((item) => {
          if (this.metadata.title === 'Service Vendors') {
            if (!item.name || !item.email) {
              this.cancelRowEdit();
              throw new Error(
                'Name and email are required for Service Vendors',
              );
            }
          }
          if (item.url) {
            if (!validateUrl(item.url)) {
              isValidData = false;
            }
          }
        }),
        switchMap((editedItem) => {
          if (!isValidData) {
            this.store.dispatch(
              AlertActions.addAlert({
                alert: { message: 'Invalid Data', type: 'error' },
              }),
            );
            return of(null);
          } else {
            if (this.editRowId?.startsWith('temp')) {
              delete editedItem._id;
              this.onEntityCreated.emit(editedItem);
            } else {
              this.onEntityUpdated.emit(editedItem);
            }
            this.editRowId = null;
            return of(null); // Return an observable to complete the chain
          }
        }),
      )
      .subscribe({
        error: (err) => {
          console.error(err.message); // Log or handle the error as needed
        },
      });
  }

  cancelRowEdit() {
    if (this.editRowId?.startsWith('temp-')) {
      this.componentStore.removeDataItem(this.editRowId);
    } else if (this.originalData[this.editRowId!]) {
      for (let col in this.originalData[this.editRowId!]) {
        this.componentStore.updateDataItem({
          entityId: this.editRowId!,
          columnKey: col,
          newValue: this.originalData[this.editRowId!][col],
        });
      }

      delete this.originalData[this.editRowId!];
    }

    this.editRowId = null;
  }

  /* Handeling accordion methods  */

  toggleEmbeddedRows(rowId: string): void {
    this.expandedRowId = this.expandedRowId === rowId ? null : rowId;
    this.onAccordionButtonClicked.emit(rowId);
  }

  onToggelClicked($event: any, _id: any, key: string) {
    this.onCellValueChange($event, _id, key);
    this.editRowId = _id;
    //save the row now
    this.confirmRowEdit();
  }

  readonly columns$ = this.componentStore.columns$;
  readonly data$ = this.componentStore.data$;
}
