import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { IProfile } from '../../core/models/profiles.model';

export const ProfilesAppActions = createActionGroup({
  source: 'Profiles',
  events: {
    'Get Profiles': emptyProps(),
    'Get Individual Profiles': emptyProps(),
    'Get Organizational Profiles': emptyProps(),
    'Search Profiles': props<{ search: string; profileType: string }>(),
    'Create Profile': props<{
      profile: {
        name: string;
        categories: string[];
        type: 'organizational' | 'individual';
        isEnabled: boolean;
      };
    }>(),
    'Update Profile': props<{
      profile: {
        name: string;
        categories: string[];
        type: 'organizational' | 'individual';
        isEnabled: boolean;
      };
      id: string;
    }>(),
    'Delete Profile': props<{ id: string }>(),
    'Enable Profile': props<{ id: string; isEnabled: boolean }>(),
  },
});

export const ProfilesApiActions = createActionGroup({
  source: 'Profiles',
  events: {
    'Get Profiles Success': props<{ profiles: IProfile[] }>(),
    'Get Profiles Failure': props<{ message: string }>(),
    'Search Profiles Success': props<{ profiles: IProfile[] }>(),
    'Search Profiles Failure': props<{ message: string }>(),
    'Create Profile Success': props<{ profile: IProfile }>(),
    'Create Profile Failure': props<{ message: string }>(),
    'Update Profile Success': props<{ profile: IProfile }>(),
    'Update Profile Failure': props<{ message: string }>(),
    'Delete Profile Success': props<{ id: string }>(),
    'Delete Profile Failure': props<{ message: string }>(),
    'Enable Profile Success': props<{ id: string; isEnabled: boolean }>(),
    'Enable Profile Failure': props<{ message: string }>(),
  },
});
