<cap-modal-container
  [modalWidthClass]="modalMetadata.modalWidth"
  [modalID]="modalMetadata.modalID"
>
  <p class="text-xl font-bold text-gray-900 dark:text-gray-50" heading>
    {{ modalMetadata.title }}
  </p>
  <div class="w-full space-y-5">
    @if (loading$ | async) {
      <div
        class="z-20 absolute top-0 left-0 w-full h-full flex justify-center items-center bg-gray-100 opacity-50 dark:opacity-30"
      >
        <cap-spinner class="z-50" [size]="16"></cap-spinner>
      </div>
    }

    <!-- FORM ERROR HANDLER END-->
    @if (errorMessage$ | async) {
      <cap-feedback
        type="error"
        [message]="errorMessage$ | async"
      ></cap-feedback>
    }
    <!--  FORM ERROR HANDLER END -->

    <!-- CATEGORY FORM START-->
    <form [formGroup]="categoryForm" (ngSubmit)="submitCategoryForm()">
      <div
        class="mt-5 grid grid-cols-1 gap-x-12 gap-y-8 sm:grid-cols-2 lg:grid-cols-6"
      >
        <!-- TODO: UPLOAD AVATAR START -->
        <cap-avatar-upload
          class="col-span-1 lg:col-span-2"
          [avatars]="avatars"
          (avatarUpdated)="onAvatarUpdated($event)"
          (avatarRemoved)="onAvatarRemoved($event)"
        ></cap-avatar-upload>
        <!-- UPLOAD AVATAR END -->

        <div class="col-span-1">
          <label
            for="name"
            class="block text-sm font-medium leading-6 text-gray-800 dark:text-gray-50"
            >Name</label
          >
          <div class="relative mt-2">
            <div
              class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
            >
              <svg
                class="h-5 w-5 text-gray-400"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  d="M7 8a3 3 0 100-6 3 3 0 000 6zM14.5 9a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM1.615 16.428a1.224 1.224 0 01-.569-1.175 6.002 6.002 0 0111.908 0c.058.467-.172.92-.57 1.174A9.953 9.953 0 017 18a9.953 9.953 0 01-5.385-1.572zM14.5 16h-.106c.07-.297.088-.611.048-.933a7.47 7.47 0 00-1.588-3.755 4.502 4.502 0 015.874 2.636.818.818 0 01-.36.98A7.465 7.465 0 0114.5 16z"
                />
              </svg>
            </div>

            <input
              type="text"
              name="name"
              formControlName="name"
              placeholder="Organization Maturity"
              id="name"
              autocomplete="given-name"
              class="block w-full rounded-md border-0 bg-white/5 py-1.5 pl-10 text-gray-800 dark:text-gray-50 dark:shadow-sm ring-1 ring-inset ring-gray-200 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary-500 dark:focus:ring-1 dark:focus:ring-inset dark:focus:ring-primary-600 sm:text-sm sm:leading-6"
            />
          </div>
          <cap-form-error-message
            [control]="categoryForm.get('name')!"
          ></cap-form-error-message>
        </div>

        <div class="col-span-1">
          <label
            for="weight"
            class="block text-sm font-medium leading-6 text-gray-800 dark:text-gray-50"
            >Weight</label
          >
          <div class="mt-2 relative">
            <div
              class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
            >
              <span class="material-symbols-outlined text-gray-400">
                weight
              </span>
            </div>
            <select
              id="weight"
              name="weight"
              formControlName="weight"
              class="block w-full pl-10 rounded-md border-0 bg-white/5 dark:bg-gray-800 py-1.5 text-gray-800 dark:text-gray-50 dark:shadow-sm ring-1 ring-inset ring-gray-200 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary-500 dark:focus:ring-1 dark:focus:ring-inset dark:focus:ring-primary-600 sm:text-sm sm:leading-6"
            >
              <option value="" disabled selected>Select Weight Scale</option>
              @for (weightScale of [0, 1, 2, 3, 4, 5]; track weightScale) {
                <option [ngValue]="weightScale">
                  {{ convertWeight(weightScale) }}
                </option>
              }
            </select>
          </div>

          <cap-form-error-message
            [control]="categoryForm.get('weight')!"
          ></cap-form-error-message>
        </div>

        <div class="col-span-1">
          <label
            class="block text-sm font-medium leading-6 text-gray-800 dark:text-gray-50"
            >Status</label
          >
          <fieldset class="mt-2">
            <div
              class="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0"
            >
              <div class="flex items-center">
                <input
                  id="active"
                  name="isActive"
                  formControlName="isActive"
                  type="radio"
                  [value]="true"
                  class="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-600"
                />
                <label
                  for="active"
                  class="ml-3 block text-sm font-medium leading-6 text-gray-800 dark:text-gray-50"
                  >Active</label
                >
              </div>
              <div class="flex items-center">
                <input
                  id="inactive"
                  name="isActive"
                  formControlName="isActive"
                  [value]="false"
                  type="radio"
                  class="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-600"
                />
                <label
                  for="inactive"
                  class="ml-3 block text-sm font-medium leading-6 text-gray-800 dark:text-gray-50"
                  >Inactive</label
                >
              </div>
            </div>
          </fieldset>

          <cap-form-error-message
            [control]="categoryForm.get('isActive')!"
          ></cap-form-error-message>
        </div>
      </div>

      @if (this.modalMetadata.isEditMode) {
        <div
          infiniteScroll
          cdkScrollable
          class="overflow-y-auto h-96"
          [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="500"
          (scrolled)="onScroll()"
          [scrollWindow]="false"
        >
          <cap-editable-table
            [isDraggable]="true"
            [metadata]="questionTableMetaData"
            [columns]="questionTableColumns"
            [data]="(questionsData$ | async) ?? []"
            [accordionMetadata]="actionItemTableMetaData"
            [accordionColumns]="actionItemTableColumns"
            [accordionData$]="actionItemsData$"
            (onEntityDeleted)="openConfirmationModal($event)"
            (onEntityUpdated)="onQuestionUpdated($event)"
            (onEntityCreated)="onQuestionCreated($event)"
            (onSortChanged)="onQuestionSortChange($event)"
            (buttonClicked)="onButtonClicked($event)"
            (onAccordionEntityCreated)="onActionItemCreated($event)"
            (onAccordionEntityUpdated)="onActionItemUpdated($event)"
            (onAccordionEntityDeleted)="openActionItemConfirmationModal($event)"
            (onAccordionButtonClicked)="getActionItemsByQuestionId($event)"
          ></cap-editable-table>
        </div>
        @if (isLoading) {
          <div class="w-full flex justify-center">
            <p class="text-gray-900, dark:text-white">Loading Questions...</p>
          </div>
        }
      }

      <div class="flex justify-between flex-row-reverse mt-5">
        <button
          type="submit"
          [disabled]="this.categoryForm.invalid"
          class="rounded-md bg-primary-600 hover:bg-primary-500 disabled:bg-primary-300 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primatext-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
        >
          {{ modalMetadata.isEditMode ? "Update" : "Create" }}
        </button>

        <button
          type="button"
          (click)="closeModal()"
          class="rounded-md bg-gray-100 hover:bg-gray-50 dark:bg-white/10 dark:hover:bg-white/20 px-3 py-2 text-sm font-semibold text-gray-800 dark:text-gray-50 shadow-sm"
        >
          Close
        </button>
      </div>
    </form>
    <!-- CATEGORY FORM END -->
  </div>
</cap-modal-container>

<cap-confirm-modal
  [modalID]="'confirm-removing-question'"
  [data]="confirmModalData"
  (confirmEvent)="removeQuestion()"
></cap-confirm-modal>

<cap-confirm-modal
  [modalID]="'confirm-removing-action-item'"
  [data]="confirmActionItemModalData"
  (confirmEvent)="removeActionItem()"
></cap-confirm-modal>

@if (selectModalVisibilityById("question-options") | async) {
  <cap-question-options-modal [questionId]="this.currentQuestionId">
  </cap-question-options-modal>
}

@if (selectModalVisibilityById("action-item-services") | async) {
  <cap-action-item-services-modal
    [actionItemId]="this.currentActionItemId"
  ></cap-action-item-services-modal>
}

@if (selectModalVisibilityById("action-item-controls") | async) {
  <cap-action-item-controls-modal
    [actionItemId]="this.currentActionItemId"
  ></cap-action-item-controls-modal>
}

@if (selectModalVisibilityById("action-item-docs") | async) {
  <cap-action-item-docs-modal
    [actionItemId]="this.currentActionItemId"
  ></cap-action-item-docs-modal>
}
