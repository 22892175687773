import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  WritableSignal,
  inject,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import {
  Observable,
  Subject,
  Subscription,
  combineLatest,
  forkJoin,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';

import { TableComponent } from 'src/app/shared/table/table.component';
import { IColumn } from 'src/app/core/models/column.model';
import {
  UsersAPIActions,
  UsersAppActions,
} from 'src/app/state/users/users.actions';
import {
  selectUserById,
  selectAll as selectUsersAccounts,
  selectTotal as selectTotalUsersCount,
} from 'src/app/state/users/users.state';
import { convertUserToUserTable } from 'src/app/utils/entity-formatting.util';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { IUserTable } from 'src/app/core/models/user.model';
import { ModalActions } from 'src/app/state/modal/modal.actions';
import { VerticalStepNavigationModalComponent } from 'src/app/shared/vertical-step-navigation-modal/vertical-step-navigation-modal.component';
import {
  IUserModalConfig,
  IVerticalModalConfig,
} from 'src/app/core/models/vertical-step.model';
import {
  selectOrganiationNameById,
  selectOrganizationsWithCurrentAvatar,
} from 'src/app/state/organizations/organizations.selectors';
import { RolesAppActions } from 'src/app/state/roles/roles.actions';
import { selectAll as selectRoles } from 'src/app/state/roles/roles.state';
import { ProfileContext } from 'src/app/core/models/profile-form-context.model';
import { selectModalVisibility } from 'src/app/state/modal/modal.state';
import { UserProfileModalComponent } from './user-profile-modal/user-profile-modal.component';
import { Actions, ofType } from '@ngrx/effects';
import { UsersListStore } from './users-list.store';
import { PaginationComponent } from '../../shared/pagination/pagination.component';
import {
  selectPaginatedUsers,
  selectPagination,
} from 'src/app/state/users/users.selectors';
import { selectProfiles } from '../../state/profiles/profiles.selectors';
import { IProfile } from '../../core/models/profiles.model';
import { ProfilesAppActions } from '../../state/profiles/profiles.actions';
import { FormsModule } from '@angular/forms';
import { sortBy } from 'lodash';

@Component({
  selector: 'cap-users-list',
  standalone: true,
  providers: [UsersListStore],
  templateUrl: './users-list.component.html',
  styleUrl: './users-list.component.scss',
  imports: [
    CommonModule,
    TableComponent,
    ConfirmModalComponent,
    VerticalStepNavigationModalComponent,
    UserProfileModalComponent,
    PaginationComponent,
    FormsModule,
  ],
})
export class UsersListComponent implements OnInit, OnDestroy {
  private store = inject(Store);
  private actions$ = inject(Actions);
  private componentStore = inject(UsersListStore);
  page: number = 1;
  pageSize: number = 10;
  totalItems: number = 0;
  totalPages: number = 0;

  userDropped: IUserTable | null = null;
  private destroy$ = new Subject<void>();
  isUserUpdateCalled: WritableSignal<boolean> = signal(false);
  isUserListLoading: boolean = false;

  addUserSetupConfig$!: Observable<IUserModalConfig>;
  updateUserSetupConfig$!: Observable<IUserModalConfig>;

  loading$ = this.componentStore.loading$;

  organizations$ = this.store.select(selectOrganizationsWithCurrentAvatar);
  profiles: IProfile[] = [];

  readonly confirmModalData = {
    title: 'Delete User',
    message: 'Are you sure you want to delete this user Account?',
  };

  users$ = this.store.select(selectUsersAccounts);
  usersData$ = this.users$.pipe(
    switchMap((users) =>
      forkJoin(
        users.map((user) =>
          this.store
            .select(selectOrganiationNameById(user.organization ?? ''))
            .pipe(
              take(1),
              map((organizationName) => ({
                ...user,
                organization: organizationName ?? 'SYSTEM',
                // Any additional transformations you wish to apply to the user object
                // can be done here to ensure immutability
              })),
            ),
        ),
      ),
    ),
    map((users) =>
      users.map((user) => convertUserToUserTable(user, this.profiles)),
    ),
  );

  selectModalVisibilityById(id: string) {
    return this.store.select(selectModalVisibility(id));
  }

  readonly columns: IColumn[] = [
    {
      key: 'avatar',
      label: 'Logo',
      type: 'avatar',
      sortable: false,
    },
    {
      key: 'info',
      label: 'Name',
      type: 'info',
      sortable: true,
    },
    {
      key: 'phoneNumber',
      label: 'Phone Number',
      type: 'text',
      sortable: true,
    },
    {
      key: 'role',
      label: 'Role',
      type: 'text',
      sortable: true,
    },
    {
      key: 'organization',
      label: 'Organization',
      type: 'text',
      sortable: true,
    },
    {
      key: 'status',
      label: 'Enabled',
      type: 'button',
      sortable: true,
    },
    {
      key: 'profile',
      label: 'Attached Profile',
      type: 'text',
      sortable: false,
    },
  ];
  roles$ = this.store.select(selectRoles);
  searchQuery: string = '';

  ngOnInit(): void {
    this.store.dispatch(ProfilesAppActions.getProfiles());
    this.store.dispatch(RolesAppActions.getRoles());

    this.store
      .select(selectTotalUsersCount)
      .pipe(
        map((usersCount) => {
          if (!usersCount) {
            this.store.dispatch(
              UsersAppActions.getUsersAccounts({
                page: this.page,
                limit: this.pageSize,
              }),
            );
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.store.select(selectPagination).subscribe((pagination) => {
      this.page = pagination.page;
      this.pageSize = pagination.limit;
      this.totalItems = pagination.totalCount;
      this.totalPages = pagination.totalPages;
    });
    this.store.select(selectProfiles).subscribe((profiles) => {
      this.profiles = profiles;
    });
    this.addUserSetupConfig$ = combineLatest([
      this.roles$,
      this.organizations$,
    ]).pipe(
      map(([roles, organizations]) => ({
        modalID: 'add-user',
        modalWidth: 'sm:max-w-xl',
        context: ProfileContext.UserCreation,
        title: 'Add User',
        data: null,
      })),
    );
  }

  openModal() {
    this.addUserSetupConfig$
      .pipe(
        tap((config) => {
          this.store.dispatch(ModalActions.open({ id: config.modalID }));
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  openUpdateUserModal(userId: string) {
    this.updateUserSetupConfig$ = this.store
      .select(selectUserById(userId))
      .pipe(
        map((user) => ({
          modalID: 'update-user',
          modalWidth: 'sm:max-w-xl',
          context: ProfileContext.UserUpdate,
          title: 'Update User',
          data: user ?? null,
        })),
      );

    // this.isUserUpdateCalled.set(true);

    this.store.dispatch(ModalActions.open({ id: 'update-user' }));
  }

  callConfirmationModal(user: any): void {
    this.userDropped = user;
    this.store.dispatch(ModalActions.open({ id: 'confirm-removing-user' }));
  }

  removeUser() {
    if (this.userDropped) {
      this.store.dispatch(
        UsersAppActions.removeUserAccount({ id: this.userDropped._id }),
      );
    }
  }
  onPageChange($event: number) {
    this.page = $event;
    this.store.dispatch(
      UsersAppActions.getUsersAccounts({
        page: this.page,
        limit: this.pageSize,
      }),
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  searchUsers() {
    this.store.dispatch(
      UsersAppActions.searchUserAccounts({
        search: this.searchQuery,
        page: this.page.toString(),
        limit: this.pageSize.toString(),
      }),
    );
  }

  updateUserStatus(obj: { effectedEntity: string; value: boolean }) {
    this.store.dispatch(
      UsersAppActions.updateUserAccountStatus({
        userId: obj.effectedEntity,
        isActive: obj.value,
      }),
    );
  }
}
