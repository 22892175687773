import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridListStore } from './grid-list.store';
import { AvatarCellComponent } from '../table/avatar-cell/avatar-cell.component';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'cap-grid-list',
  standalone: true,
  imports: [CommonModule, AvatarCellComponent, FormsModule],
  providers: [GridListStore],
  templateUrl: './grid-list.component.html',
  styleUrl: './grid-list.component.scss',
})
export class GridListComponent {
  private readonly componentStore = inject(GridListStore);
  @Output() itemSelected: EventEmitter<any> = new EventEmitter<any>();

  @Input() set items(items: any[]) {
    this.componentStore.setItems(items);
  }

  @Input() set selectedItem(selectedItem: any) {
    this.componentStore.setSelectedItem(selectedItem);
  }

  readonly items$ = this.componentStore.filteredItems$;
  readonly selectedItem$ = this.componentStore.selectedItem$;
  readonly filterValue$ = this.componentStore.filterValue$;

  onItemSelected(item: any) {
    this.componentStore.setSelectedItem(item);
    this.itemSelected.emit(item);
  }

  filterOrganizations(value: string) {
    this.componentStore.setFilterValue(value);
  }
}
