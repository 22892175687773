import { createActionGroup, emptyProps, props } from '@ngrx/store';

import {
  IActionItemBase,
  IActionItem,
  FileWithPreview,
  IDoc,
} from 'src/app/core/models/action-items.model';

export const ActionItemsAppActions = createActionGroup({
  source: 'Action Items',
  events: {
    'Get Action Items': emptyProps(),
    'Get Action Item By Id': props<{ id: string }>(),
    'Create Action Item': props<{ actionItem: IActionItemBase }>(),
    'Update Action Item': props<{ actionItem: IActionItem }>(),
    'Remove Action Item': props<{ id: string }>(),
    'Get Action Items By Question Id': props<{ questionId: string }>(),
    'Update Action Item Controls': props<{ id: string; controls: string[] }>(),
    'Update Action Item Services': props<{ id: string; services: string[] }>(),
    'Upload Action Item Docs': props<{
      id: string;
      files: FileWithPreview[];
    }>(),
    'Remove Action Item Doc': props<{ id: string; docId: string }>(),
  },
});

export const ActionItemsAPIActions = createActionGroup({
  source: 'Action Items',
  events: {
    'Get Action Items Success': props<{ actionItems: IActionItem[] }>(),
    'Get Action items Failure': props<{ message: string }>(),
    'Get Action Item By Id Success': props<{ actionItem: IActionItem }>(),
    'Get Action Item By Id Failure': props<{ message: string }>(),
    'Create Action Item Success': props<{ actionItem: IActionItem }>(),
    'Create Action Item Failure': props<{ message: string }>(),
    'Update Action Item Success': props<{ actionItem: IActionItem }>(),
    'Update Action Item Failure': props<{ message: string }>(),
    'Remove Action Item Success': props<{
      actionItemId: string;
      message: string;
    }>(),
    'Remove Action Item Failure': props<{ message: string }>(),
    'Get Action Items By Question Id Success': props<{
      actionItems: IActionItem[];
    }>(),
    'Get Action Items By Question Id Failure': props<{ message: string }>(),
    'Update Action Item Controls Success': props<{ actionItem: IActionItem }>(),
    'Update Action Item Controls Failure': props<{ message: string }>(),
    'Update Action Item Services Success': props<{ actionItem: IActionItem }>(),
    'Update Action Item Services Failure': props<{ message: string }>(),
    'Upload Action Item Docs Success': props<{
      actionItemId: string;
      docs: IDoc[];
    }>(),
    'Upload Action Item Docs Failure': props<{ message: string }>(),
    'Remove Action Item Doc Success': props<{
      actionItemId: string;
      doc: IDoc;
    }>(),
    'Remove Action Item Doc Failure': props<{ message: string }>(),
  },
});
