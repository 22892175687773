import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, of, Subject, takeUntil, tap } from 'rxjs';

import { IAvatar } from 'src/app/core/models/user.model';
import { AvatarUploadComponent } from 'src/app/shared/avatar-upload/avatar-upload.component';
import { FormErrorMessageComponent } from 'src/app/shared/form-error-message/form-error-message.component';
import { ModalContainerComponent } from 'src/app/shared/modal-container/modal-container.component';
import { SpinnerComponent } from 'src/app/shared/spinner/spinner.component';
import { VerticalStepNavigatorComponent } from 'src/app/shared/vertical-step-navigator/vertical-step-navigator.component';
import {
  CategoriesAPIActions,
  CategoriesAppActions,
} from 'src/app/state/categories/categories.actions';
import { ModalActions } from 'src/app/state/modal/modal.actions';
import { convertWeight } from 'src/app/utils/entity-formatting.util';
import { CategoryUpsertModalStore } from './category-upsert-modal.store';
import { IModalMetadata } from '../categories-list.component';
import {
  IEditableColumn,
  IEditableTableMetadata,
} from 'src/app/core/models/column.model';
import { selectQuestionsByCategoryId } from 'src/app/state/questions/questions.selectors';
import { IQuestion, IQuestionBase } from 'src/app/core/models/questions.model';
import { QuestionsAppActions } from 'src/app/state/questions/questions.actions';
import { EditableTableComponent } from 'src/app/shared/editable-table/editable-table.component';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { Actions, ofType } from '@ngrx/effects';
import { QuestionOptionsModalComponent } from './question-options-modal/question-options-modal.component';
import { selectModalVisibility } from 'src/app/state/modal/modal.state';
import {
  IActionItem,
  IActionItemBase,
} from 'src/app/core/models/action-items.model';
import { ActionItemsAppActions } from 'src/app/state/action-items/action-items.actions';
import { selectActionItemsByQuestionId } from 'src/app/state/action-items/action-items.selectors';
import { selectPagination } from 'src/app/state/questions/questions.selectors';
import { PaginationComponent } from '../../../shared/pagination/pagination.component';
import { FeedbackComponent } from '../../../shared/feedback/feedback.component';
import { ActionItemControlsModalComponent } from './action-item-controls-modal/action-item-controls-modal.component';
import { ActionItemServicesModalComponent } from './action-item-services-modal/action-item-services-modal.component';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { CdkScrollable } from '@angular/cdk/overlay';
import { ActionItemDocsModalComponent } from './action-item-docs-modal/action-item-docs-modal.component';

@Component({
  selector: 'cap-category-upsert-modal',
  standalone: true,
  providers: [CategoryUpsertModalStore],
  templateUrl: './category-upsert-modal.component.html',
  styleUrl: './category-upsert-modal.component.scss',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ModalContainerComponent,
    SpinnerComponent,
    VerticalStepNavigatorComponent,
    AvatarUploadComponent,
    FormErrorMessageComponent,
    EditableTableComponent,
    ConfirmModalComponent,
    QuestionOptionsModalComponent,
    PaginationComponent,
    FeedbackComponent,
    ActionItemControlsModalComponent,
    ActionItemServicesModalComponent,
    ActionItemDocsModalComponent,
    InfiniteScrollDirective,
    CdkScrollable,
  ],
})
export class CategoryUpsertModalComponent implements OnInit, OnDestroy {
  private destory$ = new Subject<void>();
  private questionDropped: IQuestion | null = null;
  private actionItemDropped: IActionItem | null = null;

  pagination$: any;
  currentPage: number = 1;
  pageSize: number = 10;
  totalPages: number = 0;
  totalCount: number = 0;

  @Input() modalMetadata!: IModalMetadata;

  currentQuestionId = '';
  currentActionItemId = '';
  questionsList: IQuestion[] = [];
  questionsData$: Observable<IQuestion[]> = of([]);
  actionItemsData$: Observable<IActionItem[]> = of([]);
  isLoading = false;

  questionTableMetaData: IEditableTableMetadata = {
    title: 'Questions',
    entityName: 'question',
    accordion: true,
  };

  actionItemTableMetaData: IEditableTableMetadata = {
    title: 'Action Items',
    entityName: 'Action Item',
    accordion: false,
  };

  readonly confirmModalData = {
    title: 'Delete question',
    message: 'Are you sure you want to delete this question?',
  };
  readonly confirmActionItemModalData = {
    title: 'Delete Action Item',
    message: 'Are you sure you want to delete this action item ?',
  };
  questionTableColumns: IEditableColumn[] = [
    {
      key: 'questionText',
      label: 'Question Text',
      type: 'text',
      sortable: false,
    },
    {
      key: 'weight',
      label: 'Weight',
      sortable: false,
      type: 'list',
      options: [
        {
          label: 'None',
          value: 0,
        },
        {
          label: 'Low',
          value: 1,
        },
        {
          label: 'Medium-Low',
          value: 2,
        },
        {
          label: 'Medium',
          value: 3,
        },
        {
          label: 'Medium-High',
          value: 4,
        },
        {
          label: 'High',
          value: 5,
        },
      ],
    },
    {
      key: 'options',
      label: 'Options',
      type: 'button',
      sortable: false,
    },
    {
      key: 'isActive',
      label: 'Status',
      sortable: false,
      type: 'toggle',
    },
  ];

  actionItemTableColumns: IEditableColumn[] = [
    {
      key: 'isCompleted',
      label: 'Is Completed',
      sortable: false,
      type: 'toggle',
    },
    {
      key: 'text',
      label: 'Text',
      type: 'text',
      sortable: false,
    },
    {
      key: 'weight',
      label: 'Weight',
      sortable: false,
      type: 'list',
      options: [
        {
          label: 'None',
          value: 0,
        },
        {
          label: 'Low',
          value: 1,
        },
        {
          label: 'Medium-Low',
          value: 2,
        },
        {
          label: 'Medium',
          value: 3,
        },
        {
          label: 'Medium-High',
          value: 4,
        },
        {
          label: 'High',
          value: 5,
        },
      ],
    },
    {
      key: 'controls',
      label: 'Controls',
      type: 'button',
      sortable: false,
    },
    {
      key: 'services',
      label: 'Services',
      type: 'button',
      sortable: false,
    },
    {
      key: 'docs',
      label: 'Documents',
      type: 'button',
      sortable: false,
    },
    {
      key: 'isActive',
      label: 'Status',
      sortable: false,
      type: 'toggle',
    },
  ];

  avatars: IAvatar[] = [];
  loading$ = this.componentStore.isLoading$;
  errorMessage$ = this.componentStore.errorMessaage$;

  categoryForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private readonly store: Store,
    private readonly componentStore: CategoryUpsertModalStore,
    private readonly actions$: Actions,
    private readonly router: Router,
  ) {
    this.categoryForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(5)]],
      weight: ['', [Validators.required, Validators.min(0), Validators.max(5)]],
      categoryType: ['', [Validators.required]],
      isActive: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    if (this.modalMetadata.isEditMode) {
      this.categoryForm.patchValue(this.modalMetadata.category!);
      this.avatars = this.modalMetadata.category?.avatars ?? [];
      this.questionsData$ = this.store.select(
        selectQuestionsByCategoryId(this.modalMetadata.category!._id),
      );
    } else {
      this.categoryForm.reset();
      const categoryTypePath = this.router.url.split('/').pop();
      const categoryType =
        categoryTypePath === 'individuals' ? 'individual' : 'organization';
      this.categoryForm.patchValue({ categoryType: categoryType });
      this.actions$
        .pipe(
          ofType(CategoriesAPIActions.createCategorySuccess),
          tap(({ category }) => {
            this.modalMetadata.isEditMode = true;
            this.modalMetadata.category = category;
            this.questionsData$ = this.store.select(
              selectQuestionsByCategoryId(this.modalMetadata.category!._id),
            );
          }),
          takeUntil(this.destory$),
        )
        .subscribe();
    }

    if (this.modalMetadata.isEditMode) {
      this.pagination$ = this.store
        .select(selectPagination)
        .subscribe((pagination) => {
          this.totalPages = pagination.totalPages;
          this.totalCount = pagination.totalCount;

          this.store.dispatch(
            QuestionsAppActions.getQuestionsByCategoryId({
              categoryId: this.modalMetadata.category!._id,
              page: this.currentPage,
              pageSize: this.pageSize,
            }),
          );
        });

      this.questionsData$ = this.store.select(
        selectQuestionsByCategoryId(this.modalMetadata.category!._id),
      );
      this.questionsData$.subscribe((questions) => {
        this.isLoading = false;
        this.questionsList.push(...questions);
      });
    }
  }

  convertWeight(weightScale: number) {
    return convertWeight(weightScale);
  }

  onAvatarUpdated(avatar: IAvatar) {
    // Check if the updated avatar already exists in the array
    const avatarExists = this.avatars.some((av) => av.data === avatar.data);

    // Update avatars in a single pass, setting isCurrent appropriately
    this.avatars = this.avatars.map((av) => ({
      ...av,
      // If the avatar matches the updated one, set isCurrent to true, otherwise false
      isCurrent: av.data === avatar.data,
    }));

    // If the updated avatar wasn't in the original array, add it as the current avatar
    if (!avatarExists) {
      this.avatars = [...this.avatars, { ...avatar, isCurrent: true }];
    }
  }

  onAvatarRemoved(avatar: IAvatar) {
    this.avatars = this.avatars.filter((av) => av.data !== avatar.data);
  }

  // Ensure that executive meetings include cyber security issues

  submitCategoryForm() {
    this.modalMetadata.isEditMode
      ? this.store.dispatch(
          CategoriesAppActions.updateCategory({
            category: {
              _id: this.modalMetadata.category!._id,
              sortOrder: this.modalMetadata.category!.sortOrder,
              ...this.categoryForm.value,
              avatars: this.avatars,
            },
          }),
        )
      : this.store.dispatch(
          CategoriesAppActions.createCategory({
            category: {
              ...this.categoryForm.value,
              avatars: this.avatars,
            },
          }),
        );
  }

  openConfirmationModal(question: any) {
    this.questionDropped = question;
    this.store.dispatch(ModalActions.open({ id: 'confirm-removing-question' }));
  }

  openActionItemConfirmationModal(actionItem: any) {
    this.actionItemDropped = actionItem;
    this.store.dispatch(
      ModalActions.open({ id: 'confirm-removing-action-item' }),
    );
  }

  removeQuestion() {
    if (this.questionDropped) {
      this.store.dispatch(
        QuestionsAppActions.removeQuestion({ id: this.questionDropped._id }),
      );
    }
  }

  removeActionItem() {
    if (this.actionItemDropped) {
      this.store.dispatch(
        ActionItemsAppActions.removeActionItem({
          id: this.actionItemDropped._id,
        }),
      );
    }
  }

  selectModalVisibilityById(id: string) {
    return this.store.select(selectModalVisibility(id));
  }

  onQuestionUpdated(question: any) {
    this.store.dispatch(
      QuestionsAppActions.updateQuestion({ question: <IQuestion>question }),
    );
  }

  onActionItemUpdated(actionItem: IActionItem) {
    this.store.dispatch(ActionItemsAppActions.updateActionItem({ actionItem }));
  }

  onQuestionCreated(newQuestion: any) {
    this.store.dispatch(
      QuestionsAppActions.createQuestion({
        question: {
          ...(<IQuestionBase>newQuestion),
          categoryId: this.modalMetadata.category!._id,
        },
      }),
    );
  }

  onActionItemCreated(newActionItem: IActionItemBase) {
    this.store.dispatch(
      ActionItemsAppActions.createActionItem({
        actionItem: {
          ...newActionItem,
          questionId: this.currentQuestionId,
        },
      }),
    );
  }

  onQuestionSortChange(sortInfo: {
    entityId: string;
    previousIndex: number;
    currentIndex: number;
  }) {
    this.store.dispatch(
      QuestionsAppActions.updateQuestionSortOrder({
        questionId: sortInfo.entityId,
        previousIndex: sortInfo.previousIndex,
        currentIndex: sortInfo.currentIndex,
      }),
    );
  }

  onButtonClicked(btnInfos: { entityId: string; columnKey: string }) {
    switch (btnInfos.columnKey) {
      case 'options':
        this.currentQuestionId = btnInfos.entityId;
        this.store.dispatch(ModalActions.open({ id: 'question-options' }));
        break;
      case 'controls':
        this.currentActionItemId = btnInfos.entityId;
        this.store.dispatch(ModalActions.open({ id: 'action-item-controls' }));
        break;
      case 'services':
        this.currentActionItemId = btnInfos.entityId;
        this.store.dispatch(ModalActions.open({ id: 'action-item-services' }));
        break;
      case 'docs':
        this.currentActionItemId = btnInfos.entityId;
        this.store.dispatch(ModalActions.open({ id: 'action-item-docs' }));
        break;
      default:
        console.log('Button Clicked: ', btnInfos.columnKey);
        break;
    }
  }

  closeModal() {
    this.store.dispatch(ModalActions.close({ id: this.modalMetadata.modalID }));
  }

  getActionItemsByQuestionId(questionId: string) {
    this.currentQuestionId = questionId;

    this.store.dispatch(
      ActionItemsAppActions.getActionItemsByQuestionId({ questionId }),
    );

    this.actionItemsData$ = this.store.select(
      selectActionItemsByQuestionId(questionId),
    );
  }
  onScroll() {
    if (this.currentPage < this.totalPages) {
      this.isLoading = true;
      this.store.dispatch(
        QuestionsAppActions.getQuestionsByCategoryId({
          categoryId: this.modalMetadata.category!._id,
          page: this.currentPage + 1,
          pageSize: this.pageSize,
        }),
      );
      this.currentPage++;
    }
  }

  ngOnDestroy(): void {
    this.destory$.next();
    this.destory$.complete();
  }
}
