import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { IUser } from 'src/app/core/models/user.model';
import { UsersAPIActions } from './users.actions';
import { state } from '@angular/animations';
export interface Pagination {
  page: number;
  limit: number;
  totalPages: number;
  totalCount: number;
}

export interface UsersState extends EntityState<IUser> {
  errorMessage: string | null;
  pagination: Pagination;
}

export const adapter: EntityAdapter<IUser> = createEntityAdapter<IUser>({
  selectId: (user: IUser) => user._id,
});

export const initialState: UsersState = adapter.getInitialState({
  errorMessage: null,
  pagination: {
    page: 1,
    limit: 10,
    totalPages: 0,
    totalCount: 0,
  },
});

const usersReducer = createReducer(
  initialState,
  on(
    UsersAPIActions.getUsersAccountsSuccess,
    (state, { users, pagination }) => {
      return adapter.setAll(users, {
        ...state,
        errorMessage: null,
        pagination,
      });
    },
  ),
  on(
    UsersAPIActions.searchUserAccountsSuccess,
    (state, { users, pagination }) => {
      return adapter.setAll(users, {
        ...state,
        errorMessage: null,
        pagination,
      });
    },
  ),
  on(UsersAPIActions.searchUserAccountsFailure, (state, { message }) => {
    return { ...adapter.removeAll(state), errorMessage: message };
  }),
  on(UsersAPIActions.getUsersAccountsFailure, (state, { message }) => {
    return { ...adapter.removeAll(state), errorMessage: message };
  }),
  on(
    UsersAPIActions.getUsersAccountsByOrganizationIdSuccess,
    (state, { users }) => {
      return adapter.upsertMany(users, { ...state, errorMessage: null });
    },
  ),
  on(UsersAPIActions.getAllUsersAccountsSuccess, (state, { users }) => {
    return adapter.upsertMany(users, { ...state, errorMessage: null });
  }),
  on(UsersAPIActions.getAllUsersAccountsFailure, (state, { message }) => {
    return { ...adapter.removeAll(state), errorMessage: message };
  }),
  on(
    UsersAPIActions.getUsersAccountsByOrganizationIdFailure,
    (state, { message, userIds }) => {
      return adapter.removeMany(userIds, {
        ...state,
        errorMessage: message,
      });
    },
  ),
  on(UsersAPIActions.registerNewUserSuccess, (state, { user }) => {
    return adapter.addOne(user, state);
  }),
  on(UsersAPIActions.registerNewUserFailure, (state, { message }) => {
    return { ...state, errorMessage: message };
  }),
  on(UsersAPIActions.updateUserAccountSuccess, (state, { user }) => {
    return adapter.upsertOne(user, state);
  }),
  on(UsersAPIActions.updateUserAccountFailure, (state, { message }) => {
    return { ...state, errorMessage: message };
  }),
  on(UsersAPIActions.updateUserOrganizationSuccess, (state, { user }) => {
    return adapter.upsertOne(user, state);
  }),
  on(UsersAPIActions.updateUserOrganizationFailure, (state, { message }) => {
    return { ...state, errorMessage: message };
  }),
  on(UsersAPIActions.removeUserAccountSuccess, (state, { userId }) => {
    return adapter.removeOne(userId, state);
  }),
  on(UsersAPIActions.removeUserAccountFailure, (state, { message }) => {
    return { ...state, errorMessage: message };
  }),
  on(UsersAPIActions.updateUserAccountStatusSuccess, (state, { user }) => {
    return adapter.upsertOne(user, state);
  }),
  on(UsersAPIActions.updateUserAccountStatusFailure, (state, { message }) => {
    return { ...state, errorMessage: message };
  }),
);

export const usersFeature = createFeature({
  name: 'users',
  reducer: usersReducer,
  extraSelectors: ({ selectUsersState, selectEntities, selectIds }) => ({
    ...adapter.getSelectors(selectUsersState),
    selectUserById: (userId: string) =>
      createSelector(selectEntities, (entities) => entities[userId]),
    selectUsersByOrganizationId: (organizationId: string) =>
      createSelector(selectEntities, (entities) =>
        Object.values(entities).filter(
          (user) => user?.organization === organizationId,
        ),
      ),
    selectUserIdsByOrganizationId: (organizationId: string) =>
      createSelector(selectEntities, (entities) =>
        Object.values(entities)
          .filter((user) => user?.organization === organizationId)
          .map((user) => user?._id),
      ),
  }),
});

export const {
  name,
  reducer,
  selectUsersState,
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  selectUserById,
  selectUsersByOrganizationId,
  selectUserIdsByOrganizationId,
} = usersFeature;
