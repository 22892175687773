import { Component, EventEmitter, inject, Output } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { Button } from 'primeng/button';
import { ClickOutsideDirectiveDirective } from '../directives/click-outside-directive.directive';
import { DialogModule } from 'primeng/dialog';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { PrimeTemplate, TreeNode } from 'primeng/api';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TreeModule, TreeNodeSelectEvent } from 'primeng/tree';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { IUser, UserRole } from '../../core/models/user.model';
import { selectAllUsers } from '../../state/users/users.selectors';
import { IOrganization } from '../../core/models/organization.model';
import { selectOrganizations } from '../../state/organizations/organizations.selectors';
import { DashboardPageStore } from '../../dashboard-page/dashboard-page.store';
import { UsersAppActions } from '../../state/users/users.actions';
import { OrganizationsAppActions } from '../../state/organizations/organizations.actions';
import { selectUserRole } from '../../state/auth/auth.selectors';
import { CategoriesAppActions } from '../../state/categories/categories.actions';
import { selectUser } from '../../state/auth/auth.state';
import { SharedHeaderDataService } from '../../core/services/shared-header-data.service';
import { EntityTreeVisualizationComponent } from './entity-tree-visualization/entity-tree-visualization.component';
import { SharedAssessmentHeaderService } from './shared-assessment-header.service';

@Component({
  selector: 'cap-shared-assessment-header',
  standalone: true,
  imports: [
    AsyncPipe,
    Button,
    ClickOutsideDirectiveDirective,
    DialogModule,
    OrganizationChartModule,
    PrimeTemplate,
    ProgressSpinnerModule,
    TreeModule,
    EntityTreeVisualizationComponent,
  ],
  templateUrl: './shared-assessment-header.component.html',
  styleUrl: './shared-assessment-header.component.scss',
})
export class SharedAssessmentHeaderComponent {
  private readonly store = inject(Store);
  private sharedHeaderDataService = inject(SharedHeaderDataService);
  private subscriptions: Subscription[] = [];
  private dataLoadingService = inject(SharedAssessmentHeaderService);
  individuals$: Observable<IUser[]> = this.store.select(selectAllUsers);
  organizations$: Observable<IOrganization[]> =
    this.store.select(selectOrganizations);
  entity: any;
  isAllowedToSelectOrgs: boolean = false;
  isSysAdmin: boolean = false;
  OrgSelection: boolean = false;
  treeNodes: TreeNode[] = [];
  currentAssessment: TreeNode | undefined = undefined;
  visible: boolean = false;
  dashboardPageStore = inject(DashboardPageStore);
  islandingEntitySelector = this.dashboardPageStore.select(
    (state) => state.isLoadingEntitySelector,
  );
  @Output() EntityDefined = new EventEmitter<{
    type?: 'individual' | 'organization';
    entityId?: string;
    profileId?: string;
  }>();

  ngOnInit() {
    this.sharedHeaderDataService.selectedEntity$.subscribe((selectedEntity) => {
      if (selectedEntity) {
        this.entity = selectedEntity;
      }
    });
    this.dataLoadingService.loadDataIfNeeded().subscribe((loaded) => {
      if (loaded) {
        this.loadTreeNodes();
      }
    });
  }

  buildTreeNodes(
    organizations: IOrganization[],
    individuals: IUser[],
    currentEntity: any | null,
  ): TreeNode[] {
    const treeNodes: TreeNode[] = [];

    const buildNode = (org: IOrganization): TreeNode => {
      const node: TreeNode = {
        label: org.name.trim(),
        data: org,
        children: [],
        type: 'organization',
        expanded: true,
      };

      const orgIndividuals = individuals.filter(
        (individual) => individual.organization === org._id,
      );

      orgIndividuals
        .filter((user) => user.role.name === UserRole.Individual)
        .forEach((individual) => {
          const individualNode: TreeNode = {
            label: `${individual.firstName.trim()} ${individual.lastName.trim()}`,
            data: individual,
            type: 'individual',
            leaf: true,
            children: [],
          };
          node.children?.push(individualNode);
        });

      const childOrgs = organizations.filter(
        (childOrg) => childOrg.parentOrg === org._id,
      );

      childOrgs.forEach((childOrg) => {
        node.children?.push(buildNode(childOrg));
      });

      return node;
    };

    const rootOrgs = organizations.filter((org) => !org.parentOrg);
    rootOrgs.forEach((rootOrg) => {
      const orgNode = buildNode(rootOrg);
      treeNodes.push(orgNode);
    });

    const buildOrphanNodes = (orphanOrgs: IOrganization[]): void => {
      orphanOrgs.forEach((org) => {
        if (
          !org.parentOrg ||
          organizations.find((parent) => parent._id === org.parentOrg)
        ) {
          return;
        }
        const orphanNode = buildNode(org);
        treeNodes.push(orphanNode);
      });
    };

    buildOrphanNodes(
      organizations.filter((org) => org.parentOrg && !rootOrgs.includes(org)),
    );
    if (currentEntity && currentEntity.entityId) {
      let selectedNode = this.findNodeByEntityId(
        treeNodes,
        currentEntity.entityId,
      );
      this.onNodeSelected({ node: selectedNode } as TreeNodeSelectEvent);
    } else if (treeNodes.length > 0) {
      this.onNodeSelected({ node: treeNodes[0] } as TreeNodeSelectEvent);
    }
    return treeNodes;
  }

  loadTreeNodes() {
    const roleSub = this.store.select(selectUserRole).subscribe((role) => {
      if (role && role.name === 'I') {
        this.store.select(selectUser).subscribe((user) => {
          if (user)
            this.sharedHeaderDataService.setSelectedEntity({
              type: 'individual',
              entityId: user._id,
              profileId: user.profile,
            });
        });
      } else if (
        role &&
        (role.name === UserRole.SysAdmin || role.name === UserRole.OrgAdmin)
      ) {
        this.isSysAdmin = role.name === UserRole.SysAdmin;
        combineLatest(this.individuals$, this.organizations$).subscribe(
          ([individuals, organizations]) => {
            if (organizations && individuals) {
              this.treeNodes = this.buildTreeNodes(
                organizations,
                individuals,
                this.entity,
              );
              this.isAllowedToSelectOrgs = true;
            }
          },
        );
      } else if (role && role.name === UserRole.OrgStaff) {
        this.store.select(selectUser).subscribe((user) => {
          if (user)
            this.sharedHeaderDataService.setSelectedEntity({
              type: 'organization',
              entityId: user.organization,
              profileId: user.profile,
            });
        });
      }
    });
    this.subscriptions.push(roleSub);
  }
  onNodeSelected($event: TreeNodeSelectEvent) {
    this.currentAssessment = $event.node;
    let selectedEntity = $event.node.data;
    if (selectedEntity.email) {
      this.sharedHeaderDataService.setSelectedEntity({
        type: 'individual',
        entityId: selectedEntity._id,
        profileId: selectedEntity.profile,
      });
      this.currentAssessment = $event.node;
    } else {
      this.sharedHeaderDataService.setSelectedEntity({
        type: 'organization',
        entityId: selectedEntity._id,
        profileId: selectedEntity.profile,
      });
      this.currentAssessment = $event.node;
    }
  }

  private findNodeByEntityId(
    nodes: TreeNode[],
    entityId: string,
  ): TreeNode | null {
    for (const node of nodes) {
      if (node.data._id === entityId) {
        return node;
      }
      if (node.children) {
        const childNode = this.findNodeByEntityId(node.children, entityId);
        if (childNode) {
          return childNode;
        }
      }
    }
    return null;
  }

  toggleOrgSelection() {
    this.OrgSelection = !this.OrgSelection;
  }

  closeDialog() {
    this.visible = false;
  }

  closeOrgSelection() {
    this.OrgSelection = false;
  }

  showDialog() {
    this.visible = true;
  }
  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.subscriptions = []; // Clear the subscriptions array
  }
}

export interface Entity {
  type: 'individual' | 'organization';
  entityId: string;
  profileId: string;
}
