<ng-container class="min-h-dvh w-full mb-3">
  <div class="w-full">
    <cap-shared-assessment-scroller></cap-shared-assessment-scroller>
  </div>
  @if (showStats) {
    <div class="h-full w-full flex flex-col dark:bg-slate-800">
      <div class="w-full h-full">
        <div
          class="h-full w-full flex gap-2 bg-gray-50 px-4 py-2 rounded-xl dark:bg-slate-800"
        >
          <div>
            <div
              class="w-full flex flex-col items-center rounded-xl bg-white dark:bg-slate-700"
            >
              <div class="flex flex-col items-center w-full">
                <h1>Questions</h1>
                <div class="card flex justify-content-center w-full h-full">
                  <p-chart
                    type="pie"
                    height="200"
                    [data]="this.currentAssessmentStats.questionsGraphData"
                    [options]="options"
                  />
                </div>
              </div>
            </div>
            <div
              class="w-full flex flex-col items-center rounded-xl bg-white dark:bg-slate-700"
            >
              <div class="flex flex-col items-center">
                <h1>Action Items</h1>
                <div
                  class="card flex justify-content-center relative overflow-auto"
                >
                  <p-chart
                    type="pie"
                    height="200"
                    [data]="this.currentAssessmentStats.actionItemsGraphData"
                    (onDataSelect)="onDataSelectActionItem($event)"
                    [options]="options"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="w-full flex flex-col justify-center rounded-xl bg-white dark:bg-slate-700"
          >
            <p-dropdown
              [options]="dataPointOptions"
              optionLabel="name"
              (onChange)="onSelectDataPoints($event.value)"
            ></p-dropdown>
            <div class="w-full">
              <p-chart
                type="line"
                [data]="this.currentAssessmentStats.scoreProgressionData"
                [options]="this.scoreProgressionChartOptions"
              />
            </div>
          </div>
        </div>
      </div>
      <ng-container
        *ngIf="
          currentAssessmentStats?.scoreHistory?.length || showUncompletedItems
        "
        class="h-dvh w-full"
      >
        <p-tabView styleClass="tabview-custom" [(activeIndex)]="activeIndexTab">
          <p-tabPanel>
            <ng-template pTemplate="header">
              <div
                class="flex align-items-center gap-2 h-fit"
                [ngClass]="{ 'text-red-600': activeIndexTab == 0 }"
              >
                <span class="material-symbols-outlined"> query_stats </span>
                <span class="font-bold white-space-nowrap m-0"
                  >Assessment History</span
                >
              </div>
            </ng-template>
            <div
              *ngIf="currentAssessmentStats?.scoreHistory?.length"
              class="w-full"
            >
              <div class="w-full">
                <p-table
                  [value]="this.currentAssessmentStats.scoreHistory"
                  styleClass="p-datatable-gridlines"
                  [paginator]="true"
                  [rows]="10"
                  [rowsPerPageOptions]="[5, 10, 20]"
                  [tableStyle]="{ 'min-width': '50rem' }"
                >
                  <ng-template pTemplate="header">
                    <tr>
                      <th
                        scope="col"
                        class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        pSortableColumn="userName"
                      >
                        <span class="flex"
                          >User Name <p-sortIcon field="userName"
                        /></span>
                      </th>
                      <th
                        pSortableColumn="userRole"
                        scope="col"
                        class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        <span class="flex"
                          >User Role <p-sortIcon field="userRole"
                        /></span>
                      </th>
                      <th
                        pSortableColumn="score"
                        scope="col"
                        class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        <span class="flex"
                          >Score<p-sortIcon field="score"
                        /></span>
                      </th>
                      <th
                        pSortableColumn="timestamp"
                        scope="col"
                        class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        <span class="flex"
                          >Date<p-sortIcon field="timestamp"
                        /></span>
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-product>
                    <tr>
                      <td
                        class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-600 dark:text-white sm:pl-6"
                      >
                        {{ product.userName }}
                      </td>
                      <td
                        class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                      >
                        {{ convertUserRoleName(product.userRole) }}
                      </td>
                      <td
                        class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                      >
                        {{ product.score.toFixed(0) + "%" }}
                      </td>
                      <td
                        class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                      >
                        {{ product.timestamp | date: "medium" }}
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </p-tabPanel>
          <p-tabPanel>
            <ng-template pTemplate="header">
              <div
                class="flex align-items-center gap-2"
                [ngClass]="{ 'text-red-600': activeIndexTab == 1 }"
              >
                <span class="material-symbols-outlined"> playlist_remove </span>
                <span class="font-bold white-space-nowrap m-0"
                  >Action Items</span
                >
              </div>
            </ng-template>
            <div *ngIf="showUncompletedItems" class="w-full">
              <div class="w-full">
                <p-table
                  [value]="selectedActionItems"
                  styleClass="p-datatable-gridlines"
                  [paginator]="true"
                  [loading]="isTableLoading"
                  [rows]="10"
                  [rowsPerPageOptions]="[5, 10, 20]"
                  [tableStyle]="{ 'min-width': '50rem' }"
                >
                  <ng-template pTemplate="header">
                    <tr>
                      <th pSortableColumn="text">
                        <span class="flex"
                          >Action Item <p-sortIcon field="text"
                        /></span>
                      </th>
                      <th pSortableColumn="weight">
                        <span class="flex"
                          >Weight <p-sortIcon field="weight"
                        /></span>
                      </th>
                      <th><span class="flex">Status</span></th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-product>
                    <tr
                      (mouseover)="onHoverActionItem(product)"
                      (mouseleave)="onLeaveActionItem()"
                    >
                      <td>{{ product.text }}</td>
                      <td>{{ convertWeight(product.weight) }}</td>
                      <td>
                        <div class="flex h-6 items-center">
                          <input
                            id="candidates"
                            aria-describedby="candidates-description"
                            (change)="showConfirmationDialog(product)"
                            [checked]="selectedLabel === 'Completed'"
                            name="candidates"
                            type="checkbox"
                            class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-red-600"
                          />
                        </div>
                      </td>
                    </tr>
                    <td class="relative">
                      <div
                        *ngIf="hoveredActionItem === product"
                        class="absolute top-full -mt-28 left-0 bg-white text-black p-2 border border-gray-300 rounded shadow-lg z-10"
                      >
                        <p>{{ hoveredQuestion }}</p>
                      </div>
                    </td>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </p-tabPanel>
        </p-tabView>
      </ng-container>
    </div>
  } @else {
    <div class="w-full h-full flex justify-center">
      <p-progressSpinner></p-progressSpinner>
    </div>
  }

  <p-dialog
    header="Confirm Action"
    [(visible)]="displayConfirmation"
    modal="modal"
    [closable]="false"
  >
    <p>Are you sure you want to change the status of this action item?</p>
    <p-footer class="flex justify-end gap-4">
      <button
        type="button"
        class="rounded bg-red-600 px-8 py-2 text-xs font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
        (click)="confirmChangeStatus()"
      >
        Yes
      </button>
      <button
        type="button"
        class="rounded bg-red-600 px-8 py-2 text-xs font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
        (click)="cancelChangeStatus()"
      >
        No
      </button>
    </p-footer>
  </p-dialog>
</ng-container>
