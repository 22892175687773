import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  finalize,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { AsyncPipe, NgStyle } from '@angular/common';
import { Table, TableModule } from 'primeng/table';
import { Button } from 'primeng/button';
import { TagModule } from 'primeng/tag';
import { convertWeight } from '../../utils/entity-formatting.util';
import { DividerModule } from 'primeng/divider';
import { MultiSelectModule } from 'primeng/multiselect';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { SharedHeaderDataService } from '../../core/services/shared-header-data.service';
import { ReportsService } from '../../core/services/reports.service';
import { ICategory } from '../../core/models/categories.model';
import { IActionItem } from '../../core/models/action-items.model';
import { IQuestion } from '../../core/models/questions.model';
import { IAssessment } from '../../core/models/assessments.model';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { Entity } from '../../shared/shared-assessment-header/shared-assessment-header.component';
import { HttpResponse } from '@angular/common/http';
import { DialogModule } from 'primeng/dialog';
import { IService } from '../../core/models/services.model';
import { ISecurityControl } from '../../core/models/security-controls.model';
import { CheckboxModule } from 'primeng/checkbox';
import { Store } from '@ngrx/store';
import { AlertActions } from '../../state/alert/alert.actions';
import { ButtonModule } from 'primeng/button';

interface QuestionWithActionItems {
  id: string;
  questionText: string;
  weight: 0 | 1 | 2 | 3 | 4 | 5;
  categoryName: string;
  actionItems: {
    id: string;
    text: string;
    weight: 0 | 1 | 2 | 3 | 4 | 5;
    isCompleted: boolean;
  }[];
}

@Component({
  selector: 'cap-report-page-assessment-section',
  standalone: true,
  imports: [
    NgStyle,
    TableModule,
    Button,
    TagModule,
    DividerModule,
    AsyncPipe,
    MultiSelectModule,
    FormsModule,
    DropdownModule,
    ProgressSpinnerModule,
    DialogModule,
    CheckboxModule,
    ButtonModule,
  ],
  templateUrl: './report-page-assessment-section.component.html',
  styleUrl: './report-page-assessment-section.component.scss',
})
export class ReportPageAssessmentSectionComponent implements OnInit, OnDestroy {
  private reportsService = inject(ReportsService);
  private store = inject(Store);
  private categoryFilterSubject = new BehaviorSubject<string | null>(null);
  private fullQuestionSetSubject = new BehaviorSubject<
    QuestionWithActionItems[]
  >([]);
  private fullQuestionSet: QuestionWithActionItems[] = [];
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private currentEntity: Entity | null = null;
  private destroy$ = new Subject<void>();
  sharedHeaderDataService = inject(SharedHeaderDataService);
  @ViewChild('dt') dt: Table | undefined;
  questionsWithActionItems$: BehaviorSubject<QuestionWithActionItems[]> =
    new BehaviorSubject<QuestionWithActionItems[]>([]);
  categories$ = new BehaviorSubject<{ label: string; value: string }[]>([]);
  expandedRows: { [key: string]: boolean } = {};
  categoryFilter: string | null = null;
  weightFilter: number | null = null;
  loading$ = this.loadingSubject.asObservable();
  showServices = false;
  showControls = false;
  confirmPdf = false;
  services: IService[] = [];
  controls: ISecurityControl[] = [];
  protected readonly convertWeight = convertWeight;
  protected readonly Array = Array;
  includeServices: boolean = false;
  includeControls: boolean = false;

  readonly weights = [
    { label: 'None', value: 0 },
    { label: 'Low', value: 1 },
    { label: 'Medium-Low', value: 2 },
    { label: 'Medium', value: 3 },
    { label: 'Medium-High', value: 4 },
    { label: 'High', value: 5 },
  ];
  isCompletedOptions: any[] = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  // Add global filter text property
  globalFilterText: string = '';

  ngOnInit() {
    this.loadData();
    this.setupCategoryFilter();
    // Apply initial filter after data is loaded
    this.sharedHeaderDataService.selectedCategory$
      .pipe(take(1))
      .subscribe((category) => {
        if (category && category.category) {
          this.categoryFilter = category.category.name;
          this.applyFilters();
        }
      });
    this.sharedHeaderDataService.selectedEntity$
      .pipe(takeUntil(this.destroy$))
      .subscribe((entity) => {
        if (entity) {
          this.currentEntity = entity;
        }
      });
  }

  private setupCategoryFilter() {
    this.sharedHeaderDataService.selectedCategory$
      .pipe(takeUntil(this.destroy$))
      .subscribe((category) => {
        if (category && category.category) {
          this.categoryFilter = category.category.name;
          this.applyFilters();
        } else {
          this.clear(); // This will reset to all questions
        }
      });
  }

  // Modify applyFilter() to applyFilters() and include global filter logic
  applyFilters() {
    let filteredQuestions = this.fullQuestionSet;

    // Apply category filter
    if (this.categoryFilter) {
      filteredQuestions = filteredQuestions.filter(
        (q) => q.categoryName === this.categoryFilter,
      );
    }

    // Apply weight filter
    if (this.weightFilter !== undefined && this.weightFilter !== null) {
      filteredQuestions = filteredQuestions.filter(
        (q) => q.weight === this.weightFilter,
      );
    }

    // Apply global filter
    if (this.globalFilterText && this.globalFilterText.trim() !== '') {
      const filterText = this.globalFilterText.toLowerCase();

      filteredQuestions = filteredQuestions.filter((q) => {
        // Check if the question text matches
        const questionMatch = q.questionText.toLowerCase().includes(filterText);
        // Check if any of the action items match
        const actionItemMatch = q.actionItems.some((ai) =>
          ai.text.toLowerCase().includes(filterText),
        );
        return questionMatch || actionItemMatch;
      });
    }

    this.questionsWithActionItems$.next(filteredQuestions);
    this.expandAll();
  }

  onLocalCategoryFilterChange(event: any) {
    const filterValue = event.value;
    if (filterValue) {
      this.categoryFilter = filterValue;
      this.applyFilters();
    } else {
      this.clear();
    }
  }

  onWeightFilterChange(value: any) {
    this.weightFilter = value;
    this.applyFilters();
  }

  private clearFilter() {
    if (this.dt) {
      this.dt.filter(null, 'categoryName', 'equals');
    }
  }

  private loadData() {
    this.loadingSubject.next(true);
    this.sharedHeaderDataService.selectedEntity$
      .pipe(
        takeUntil(this.destroy$),
        switchMap((entity) => {
          if (!entity) return of(null);
          return this.reportsService.getReport(entity);
        }),
        tap((data) => {
          if (data) {
            const transformedData = this.transformData(data);
            this.fullQuestionSet = transformedData;
            this.questionsWithActionItems$.next(transformedData);

            const uniqueCategories = Array.from(
              new Set(transformedData.map((q) => q.categoryName)),
            );
            this.categories$.next(
              uniqueCategories.map((cat) => ({ label: cat, value: cat })),
            );

            // Apply initial filter after data is loaded
            this.applyInitialFilter();
          }
        }),
        finalize(() => this.loadingSubject.next(false)),
      )
      .subscribe();
  }

  private applyInitialFilter() {
    this.sharedHeaderDataService.selectedCategory$
      .pipe(take(1))
      .subscribe((category) => {
        if (category && category.category) {
          this.categoryFilter = category.category.name;
          this.applyFilters();
        }
      });
  }

  private transformData(data: any): QuestionWithActionItems[] {
    if (!data || !Array.isArray(data.questions)) {
      console.warn('No valid questions data available');
      return [];
    }

    const { assessments, categories, questions, actionItems } = data;

    // Create a map of question IDs to their completed action item IDs from assessments
    const questionCompletedActionItemMap = new Map<string, Set<string>>();
    assessments.forEach((assessment: IAssessment) => {
      assessment.questionsResponses.forEach((response) => {
        if (!questionCompletedActionItemMap.has(response.questionId)) {
          questionCompletedActionItemMap.set(response.questionId, new Set());
        }
        response.actionItemsIds.forEach((actionItemId) => {
          questionCompletedActionItemMap
            .get(response.questionId)!
            .add(actionItemId);
        });
      });
    });

    return questions.map((question: IQuestion): QuestionWithActionItems => {
      const questionActionItems = actionItems
        .filter((ai: IActionItem) => ai.questionId === question._id)
        .map((actionItem: IActionItem) => {
          const isCompleted =
            questionCompletedActionItemMap
              .get(question._id)
              ?.has(actionItem._id) || false;
          return {
            id: actionItem._id,
            text: actionItem.text,
            weight: actionItem.weight,
            isCompleted: isCompleted,
            services: actionItem.services,
            controls: actionItem.controls,
          };
        });

      return {
        id: question._id,
        questionText: question.questionText,
        weight: question.weight,
        categoryName: this.getCategoryNameById(question.categoryId, categories),
        actionItems: questionActionItems,
      };
    });
  }

  private getCategoryNameById(
    categoryId: string,
    categories: ICategory[],
  ): string {
    if (!Array.isArray(categories)) {
      console.warn('Categories is not an array:', categories);
      return 'Unknown Category';
    }
    const category = categories.find((c) => c._id === categoryId);
    return category ? category.name : 'Unknown Category';
  }

  expandAll() {
    this.questionsWithActionItems$
      ?.pipe(takeUntil(this.destroy$))
      .subscribe((questions) => {
        questions.forEach((question) => {
          this.expandedRows[question.id] = true;
        });
      });
  }

  collapseAll() {
    this.expandedRows = {};
  }

  onRowExpand(event: any) {
    // Future feature
  }

  onRowCollapse(event: any) {
    // Future feature
  }

  clear() {
    this.categoryFilter = null;
    this.weightFilter = null;
    this.globalFilterText = '';
    this.applyFilters();
  }

  onCategoryFilterChange(category: string | null) {
    this.categoryFilterSubject.next(category);
  }

  downloadPdf() {
    if (this.currentEntity)
      this.reportsService
        .generateReport(
          this.currentEntity,
          this.categoryFilter,
          this.weightFilter,
          this.includeControls,
          this.includeServices,
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (response: HttpResponse<Blob>) => {
            if (!response.body) {
              console.error('Response body is empty');
            } else {
              const blob = new Blob([response.body], {
                type: 'application/pdf',
              });
              const downloadUrl = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = downloadUrl;
              link.download = 'report.pdf'; // or any other name you want
              link.click();
              window.URL.revokeObjectURL(downloadUrl);
            }
          },
          error: (error) => {
            console.error('Error downloading the file:', error);
            // Handle error (show user-friendly message, etc.)
          },
        });
    this.confirmPdf = false;
    this.store.dispatch(
      AlertActions.addAlert({
        alert: {
          type: 'success',
          message:
            'Report is being generated. Please wait for the download to start.',
        },
      }),
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onShowService(actionItem: any) {
    this.showServices = !this.showServices;
    this.services = actionItem.services;
  }
  onShowControls(actionItem: any) {
    this.showControls = !this.showControls;
    this.controls = actionItem.controls;
  }

  showFilters = false;

  toggleFilters() {
    this.showFilters = !this.showFilters;
  }

  showActionItemFilter = false;

  toggleActionItemFilter() {
    this.showActionItemFilter = !this.showActionItemFilter;
  }
}
