<div class="w-full flex justify-end mb-2">
  <input
    type="text"
    [ngModel]="filterValue$ | async"
    (ngModelChange)="filterOrganizations($event)"
    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
    placeholder="organization name"
  />
</div>
<ul
  role="list"
  class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-4 sm:gap-x-4 lg:grid-cols-5 xl:gap-x-8"
>
  @for (item of items$ | async; track item.id) {
    <li class="relative">
      <div
        class="group aspect-h-1 aspect-w-1 block overflow-hidden rounded-full bg-gray-100"
        [ngClass]="{
          'ring-2 ring-primary-500 ring-offset-2 ring-offset-gray-100':
            (selectedItem$ | async) === item
        }"
      >
        <!-- focus-within:ring-2 focus-within:ring-primary-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 -->
        @if (item.avatar) {
          <img
            [src]="item.avatar.data"
            alt="Avatar"
            class="pointer-events-none object-cover group-hover:opacity-75"
          />
        } @else {
          <img
            src="assets/images/default-avatar.jpeg"
            alt="Avatar"
            class="pointer-events-none object-cover group-hover:opacity-75"
          />
        }

        <button
          type="button"
          (click)="onItemSelected(item)"
          class="absolute inset-0 focus:outline-none"
        >
          <span
            class="sr-only mt-6 text-base font-semibold leading-7 tracking-tight text-gray-900"
            >{{ item.name }}</span
          >
        </button>
      </div>
      <p
        class="pointer-events-none mt-6 block text-sm text-center font-medium text-gray-900"
      >
        {{ item.name }}
      </p>
      <p
        class="pointer-events-none block text-sm text-center font-medium text-gray-500"
      >
        {{ item.phoneNumber }}
      </p>
    </li>
  }
</ul>
