import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import {
  IServiceBase,
  IService,
  IServiceSource,
  IServiceSourceBase,
  IServiceVendor,
  IServiceVendorBase,
} from '../models/services.model';
import { Pagination } from '../../state/services/services.state';

@Injectable({
  providedIn: 'root',
})
export class ServicesService {
  private readonly http = inject(HttpClient);
  private servicesPath = 'services';
  private serviceSourcesPath = 'sources';
  private serviceVendorsPath = 'vendors';

  getServices(
    page?: number,
    pageSize?: number,
  ): Observable<{ services: IService[]; pagination: Pagination }> {
    if (page && pageSize) {
      return this.http.get<{ services: IService[]; pagination: Pagination }>(
        `${environment.apiUrl}/${this.servicesPath}?page=${page}&pageSize=${pageSize}`,
      );
    } else {
      return this.http.get<{ services: IService[]; pagination: Pagination }>(
        `${environment.apiUrl}/${this.servicesPath}`,
      );
    }
  }

  getServiceById(serviceId: string): Observable<{ service: IService }> {
    return this.http.get<{ service: IService }>(
      `${environment.apiUrl}/${this.servicesPath}/${serviceId}`,
    );
  }

  createService(service: IServiceBase): Observable<{ service: IService }> {
    return this.http.post<{ service: IService }>(
      `${environment.apiUrl}/${this.servicesPath}`,
      service,
    );
  }

  updateService(service: IService): Observable<{ service: IService }> {
    const { _id, ...serviceData } = service;
    return this.http.put<{ service: IService }>(
      `${environment.apiUrl}/${this.servicesPath}/${_id}`,
      serviceData,
    );
  }

  removeService(
    serviceId: string,
  ): Observable<{ serviceId: string; message: string }> {
    return this.http.delete<{ serviceId: string; message: string }>(
      `${environment.apiUrl}/${this.servicesPath}/${serviceId}`,
    );
  }

  getServiceSources(
    page?: number,
    pageSize?: number,
  ): Observable<{
    serviceSources: IServiceSource[];
    pagination: Pagination;
  }> {
    if (page && pageSize) {
      return this.http.get<{
        serviceSources: IServiceSource[];
        pagination: Pagination;
      }>(
        `${environment.apiUrl}/${this.servicesPath}/${this.serviceSourcesPath}?page=${page}&pageSize=${pageSize}`,
      );
    } else {
      return this.http.get<{
        serviceSources: IServiceSource[];
        pagination: Pagination;
      }>(
        `${environment.apiUrl}/${this.servicesPath}/${this.serviceSourcesPath}`,
      );
    }
  }

  getServiceSourceById(
    sourceId: string,
  ): Observable<{ serviceSource: IServiceSource }> {
    return this.http.get<{ serviceSource: IServiceSource }>(
      `${environment.apiUrl}/${this.servicesPath}/${this.serviceSourcesPath}/${sourceId}`,
    );
  }

  createServiceSource(
    source: IServiceSourceBase,
  ): Observable<{ serviceSource: IServiceSource }> {
    return this.http.post<{ serviceSource: IServiceSource }>(
      `${environment.apiUrl}/${this.servicesPath}/${this.serviceSourcesPath}`,
      source,
    );
  }

  updateServiceSource(
    source: IServiceSource,
  ): Observable<{ serviceSource: IServiceSource }> {
    const { _id, ...sourceData } = source;
    return this.http.put<{ serviceSource: IServiceSource }>(
      `${environment.apiUrl}/${this.servicesPath}/${this.serviceSourcesPath}/${_id}`,
      sourceData,
    );
  }

  removeServiceSource(
    sourceId: string,
  ): Observable<{ serviceSourceId: string; message: string }> {
    return this.http.delete<{ serviceSourceId: string; message: string }>(
      `${environment.apiUrl}/${this.servicesPath}/${this.serviceSourcesPath}/${sourceId}`,
    );
  }

  getServiceVendors(
    page?: number,
    pageSize?: number,
  ): Observable<{ serviceVendors: IServiceVendor[]; pagination: Pagination }> {
    if (page && pageSize) {
      return this.http.get<{
        serviceVendors: IServiceVendor[];
        pagination: Pagination;
      }>(
        `${environment.apiUrl}/${this.servicesPath}/${this.serviceVendorsPath}?page=${page}&pageSize=${pageSize}`,
      );
    } else {
      return this.http.get<{
        serviceVendors: IServiceVendor[];
        pagination: Pagination;
      }>(
        `${environment.apiUrl}/${this.servicesPath}/${this.serviceVendorsPath}`,
      );
    }
  }

  getServiceVendorById(
    vendorId: string,
  ): Observable<{ serviceVendor: IServiceVendor }> {
    return this.http.get<{ serviceVendor: IServiceVendor }>(
      `${environment.apiUrl}/${this.servicesPath}/${this.serviceVendorsPath}/${vendorId}`,
    );
  }

  createServiceVendor(
    vendor: IServiceVendorBase,
  ): Observable<{ serviceVendor: IServiceVendor }> {
    return this.http.post<{ serviceVendor: IServiceVendor }>(
      `${environment.apiUrl}/${this.servicesPath}/${this.serviceVendorsPath}`,
      vendor,
    );
  }

  updateServiceVendor(
    vendor: IServiceVendor,
  ): Observable<{ serviceVendor: IServiceVendor }> {
    const { _id, ...vendorData } = vendor;
    return this.http.put<{ serviceVendor: IServiceVendor }>(
      `${environment.apiUrl}/${this.servicesPath}/${this.serviceVendorsPath}/${_id}`,
      vendorData,
    );
  }

  removeServiceVendor(
    vendorId: string,
  ): Observable<{ serviceVendorId: string; message: string }> {
    return this.http.delete<{ serviceVendorId: string; message: string }>(
      `${environment.apiUrl}/${this.servicesPath}/${this.serviceVendorsPath}/${vendorId}`,
    );
  }
}
