import { Injectable, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class AvatarService {
  private store = inject(Store);

  private subs: Subscription[] = [];

  private Dimensions = { width: 3000, height: 3000 };
  get maxDimensions() {
    return this.Dimensions;
  }

  validateImageDimensions(
    file: File,
    maxDimensions: { width: number; height: number }
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // Create a FileReader to read the file
      const reader = new FileReader();

      // Define the onload event handler for the FileReader
      reader.onload = (e: any) => {
        // Create a new Image to load the file data into
        const img = new Image();

        // Define the onload event handler for the Image
        img.onload = () => {
          // Check if the image dimensions are within the specified maximum dimensions
          if (
            img.width <= maxDimensions.width &&
            img.height <= maxDimensions.height
          ) {
            resolve(true); // Image dimensions are valid
          } else {
            resolve(false); // Image dimensions exceed maximum size
          }
        };

        // Define the onerror event handler for the Image
        img.onerror = () => {
          reject(new Error('There was an error loading the image.'));
        };

        // Set the source of the Image to the result from the FileReader
        img.src = e.target.result;
      };

      // Define the onerror event handler for the FileReader
      reader.onerror = () => {
        reject(new Error('There was an error reading the file.'));
      };

      // Read the file as a Data URL to trigger the onload event
      reader.readAsDataURL(file);
    });
  }
}
