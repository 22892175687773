<div class="mt-8 flex flex-col">
  <div class="flex justify-between">
    <p class="text-lg font-bold text-gray-900 dark:text-gray-50" heading>
      {{ metadata.title }}
    </p>

    <button
      type="button"
      (click)="addRow()"
      class="block rounded-md bg-primary-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    >
      Add {{ metadata.entityName }}
    </button>
  </div>
  <table class="min-w-full divide-y divide-gray-300 dark:divide-gray-500">
    <thead>
      <tr>
        @if (metadata.accordion) {
          <th scope="col" class="w-16"></th>
        }
        @for (col of columns$ | async; track col.key; let isFirst = $first) {
          <th
            scope="col"
            class="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
            [ngClass]="{ 'sm:pl-0': isFirst }"
          >
            {{ col.label }}
          </th>
        }
        <th scope="col" class="relative py-3.5 px-3 sm:pr-0">
          <span class="sr-only">Edit</span>
        </th>
      </tr>
    </thead>
    @if (isDraggable) {
      <tbody
        cdkDropList
        (cdkDropListDropped)="drop($event)"
        class="divide-y divide-gray-400 dark:divide-gray-600"
      >
        @if ((data$ | async)?.length === 0) {
          <tr>
            <td
              [attr.colspan]="(columns$ | async)!.length + 2"
              class="text-center py-5"
            >
              <p class="text-gray-500 dark:text-gray-400">
                No {{ metadata.entityName }} found
              </p>
            </td>
          </tr>
        }
        @for (item of data$ | async; track item.id) {
          <tr
            [attr.data-row-id]="item._id"
            cdkDrag
            [class.opacity-50]="isDragging"
            [class.shadow-sm]="isDragging"
            (cdkDragStarted)="onDragStarted($event)"
            (cdkDragReleased)="onDragReleased($event)"
            class="bg-white hover:cursor-move active:cursor-grabbing transition-shadow"
            [ngClass]="metadata.bgColor ?? 'dark:bg-gray-800'"
          >
            <div
              class="animate-pulse bg-gray-300 dark:bg-gray-700"
              *cdkDragPlaceholder
            ></div>

            @if (metadata.accordion) {
              <td class="w-16">
                <button
                  class="disabled:text-gray-200 dark:text-gray-300 dark:disabled:text-gray-700"
                  [disabled]="editRowId === item._id"
                  type="button"
                  (click)="toggleEmbeddedRows(item._id)"
                >
                  <span class="material-symbols-outlined">{{
                    expandedRowId === item._id ? "expand_less" : "expand_more"
                  }}</span>
                </button>
              </td>
            }

            @for (
              col of columns$ | async;
              track col.key;
              let isFirst = $first
            ) {
              <td
                class="whitespace-nowrap py-2 pl-4 pr-3 text-sm"
                [ngClass]="{ 'sm:pl-0': isFirst }"
              >
                <!-- Read Mode cells -->
                @if (editRowId !== item._id) {
                  @switch (col.type) {
                    @case ("text") {
                      @if (metadata.title === "Questions") {
                        <cap-text-cell
                          [text]="item[col.key]"
                          [isQuestion]="true"
                        ></cap-text-cell>
                      } @else {
                        <cap-text-cell [text]="item[col.key]"></cap-text-cell>
                      }
                    }
                    @case ("description") {
                      <cap-text-cell [text]="item[col.key]"></cap-text-cell>
                    }
                    @case ("radio") {
                      <cap-text-cell [text]="item[col.key]"></cap-text-cell>
                    }
                    @case ("list") {
                      <cap-text-cell
                        [text]="
                          col.key === 'weight'
                            ? convertWeight(item[col.key])
                            : col.key === 'standardId' ||
                                col.key === 'sourceId' ||
                                col.key === 'vendorId'
                              ? (getEntityRowNameById(col.key, item[col.key])
                                | async)
                              : item[col.key]
                        "
                      ></cap-text-cell>
                    }
                    @case ("button") {
                      <cap-edit-button-cell
                        (buttonClicked)="onButtonClicked(item._id, col.key)"
                        [label]="col.label"
                      ></cap-edit-button-cell>
                    }
                    @case ("status") {
                      <cap-status-cell
                        [status]="item[col.key]"
                        [props]="col.options!"
                      ></cap-status-cell>
                    }
                    @case ("checkbox") {
                      <cap-status-cell
                        [status]="item[col.key]"
                        [props]="
                          col.options ?? [
                            { label: 'Positive', value: true },
                            { label: 'Negative', value: false }
                          ]
                        "
                      ></cap-status-cell>
                    }
                    @case ("badge") {
                      <cap-badge-cell [badges]="item[col.key]"></cap-badge-cell>
                    }
                    @case ("date") {
                      <cap-date-cell [date]="item[col.key]"></cap-date-cell>
                    }
                    @case ("cost") {
                      <cap-cost-cell [value]="item[col.key]"></cap-cost-cell>
                    }
                    @case ("toggle") {
                      <cap-edit-toggle-button-cell
                        (checkChange)="
                          onToggelClicked($event, item._id, col.key)
                        "
                        [button]="item[col.key]"
                      ></cap-edit-toggle-button-cell>
                    }
                    <!-- Other column types -->
                    @default {
                      <p></p>
                    }
                  }
                }
                <!-- Edit Mode cells -->
                @if (editRowId === item._id) {
                  @switch (col.type) {
                    @case ("text") {
                      <cap-edit-text-cell
                        [text]="item[col.key]"
                        (textChange)="
                          onCellValueChange($event, item._id, col.key)
                        "
                      ></cap-edit-text-cell>
                    }
                    @case ("description") {
                      <cap-edit-text-area-cell
                        [description]="item[col.key]"
                        (descriptionChange)="
                          onCellValueChange($event, item._id, col.key)
                        "
                      ></cap-edit-text-area-cell>
                    }
                    @case ("radio") {
                      <cap-edit-radio-cell
                        [options]="col.options!"
                        [selectedOption]="item[col.key]"
                        [groupName]="col.key"
                        (optionChange)="
                          onCellValueChange($event, item._id, col.key)
                        "
                      ></cap-edit-radio-cell>
                    }
                    @case ("list") {
                      <cap-edit-list-cell
                        [options]="col.options!"
                        [selectedOption]="item[col.key]"
                        (optionChange)="
                          onCellValueChange($event, item._id, col.key)
                        "
                      ></cap-edit-list-cell>
                    }
                    @case ("button") {
                      <cap-edit-button-cell
                        (buttonClicked)="onButtonClicked(item._id, col.key)"
                        [label]="col.label"
                        [disabled]="false"
                      ></cap-edit-button-cell>
                    }
                    @case ("status") {
                      <cap-edit-status-cell
                        [options]="col.options!"
                        [groupName]="col.key"
                        [selectedOption]="item[col.key]"
                        (optionChange)="
                          onCellValueChange($event, item._id, col.key)
                        "
                      ></cap-edit-status-cell>
                    }
                    @case ("checkbox") {
                      <cap-edit-checkbox-cell
                        [isChecked]="item[col.key]"
                        [groupName]="item._id + '-' + col.key"
                        [label]="'Is Positive'"
                        (checkChange)="
                          onCellValueChange($event, item._id, col.key)
                        "
                      ></cap-edit-checkbox-cell>
                    }
                    @case ("badge") {
                      <cap-edit-badge-cell
                        [suggestedBadges]="
                          (suggestedStandardGroupings | async)!
                        "
                        [badges]="item[col.key] ?? []"
                        (badgesChange)="
                          onCellValueChange($event, item._id, col.key)
                        "
                      ></cap-edit-badge-cell>
                    }
                    @case ("date") {
                      <cap-date-cell [date]="item[col.key]"></cap-date-cell>
                    }
                    @case ("cost") {
                      <cap-edit-status-cell
                        [options]="col.options!"
                        [groupName]="col.key"
                        [selectedOption]="item[col.key]"
                        (optionChange)="
                          onCellValueChange($event, item._id, col.key)
                        "
                      ></cap-edit-status-cell>
                    }
                    @case ("toggle") {
                      <cap-edit-toggle-button-cell
                        (checkChange)="
                          onToggelClicked($event, item._id, col.key)
                        "
                        [button]="item[col.key]"
                      ></cap-edit-toggle-button-cell>
                    }
                    <!-- Other column types -->
                    @default {
                      <p></p>
                    }
                  }
                }
              </td>
            }
            <td
              class="relative h-16 flex justify-end items-center gap-x-4 whitespace-nowrap py-3 pl-3 pr-4 text-sm font-medium sm:pr-0"
            >
              <!--Action Buttons -->
              @if (editRowId !== item._id) {
                <button
                  type="button"
                  (click)="onUpdateClicked(item._id)"
                  class="text-indigo-600 hover:text-indigo-800 dark:text-indigo-500 dark:hover:text-indigo-400"
                >
                  Edit
                </button>
                <button
                  type="button"
                  (click)="onDeleteClicked(item)"
                  class="text-red-600 hover:text-red-800 dark:text-red-500 dark:hover:text-red-400 mr-2"
                >
                  Remove
                </button>
              }

              <!-- Edit Mode Actions -->
              @if (editRowId === item._id) {
                <button
                  type="button"
                  (click)="confirmRowEdit()"
                  class="rounded-full p-1 flex justify-center items-center bg-gray-100 hover:bg-green-500 dark:bg-gray-700 dark:hover:bg-green-500 text-gray-800 hover:text-gray-50 dark:text-gray-50 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                >
                  <span class="material-symbols-outlined"> check </span>
                </button>
                <button
                  type="button"
                  (click)="cancelRowEdit()"
                  class="rounded-full p-1 flex justify-center bg-gray-100 hover:bg-primary-500 dark:bg-gray-700 dark:hover:bg-primary-500 text-gray-800 hover:text-gray-50 dark:text-gray-50 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                >
                  <span class="material-symbols-outlined"> close </span>
                </button>
              }
            </td>
          </tr>
          @if (item._id === expandedRowId) {
            <tr>
              <td [attr.colspan]="(columns$ | async)!.length + 2">
                <cap-edit-accordion-section
                  [metadata]="accordionMetadata"
                  [columns]="accordionColumns"
                  [data]="(accordionData$ | async)!"
                  (buttonClicked)="onAccordionButtonsClicked($event)"
                  (onEntityCreated)="onAccordionEntityCreated.emit($event)"
                  (onEntityUpdated)="onAccordionEntityUpdated.emit($event)"
                  (onEntityDeleted)="onAccordionEntityDeleted.emit($event)"
                ></cap-edit-accordion-section>
              </td>
            </tr>
          }
        }
      </tbody>
    } @else {
      <tbody class="divide-y divide-gray-400 dark:divide-gray-600">
        @if ((data$ | async)?.length === 0) {
          <tr>
            <td
              [attr.colspan]="(columns$ | async)!.length + 2"
              class="text-center py-5"
            >
              <p class="text-gray-500 dark:text-gray-400">
                No {{ metadata.entityName }} found
              </p>
            </td>
          </tr>
        }
        @for (item of data$ | async; track item.id) {
          <tr
            [attr.data-row-id]="item._id"
            class="bg-white"
            [ngClass]="metadata.bgColor ?? 'dark:bg-gray-800'"
          >
            @if ((data$ | async)?.length === 0) {
              <td class="w-16">
                <button
                  class="disabled:text-gray-200 dark:text-gray-300 dark:disabled:text-gray-700"
                  [disabled]="editRowId === item._id"
                  type="button"
                  (click)="toggleEmbeddedRows(item._id)"
                >
                  <p class="text-gray-500 dark:text-gray-400">
                    No {{ metadata.entityName }} found
                  </p>
                </button>
              </td>
            }
          </tr>
        }
        @for (item of data$ | async; track item.id) {
          <tr
            [attr.data-row-id]="item._id"
            class="bg-white"
            [ngClass]="metadata.bgColor ?? 'dark:bg-gray-800'"
          >
            @if (metadata.accordion) {
              <td class="w-16">
                <button
                  class="disabled:text-gray-200 dark:text-gray-300 dark:disabled:text-gray-700"
                  [disabled]="editRowId === item._id"
                  type="button"
                  (click)="toggleEmbeddedRows(item._id)"
                >
                  <span class="material-symbols-outlined">{{
                    expandedRowId === item._id ? "expand_less" : "expand_more"
                  }}</span>
                </button>
              </td>
            }

            @for (
              col of columns$ | async;
              track col.key;
              let isFirst = $first
            ) {
              <td
                class="whitespace-nowrap py-2 pl-4 pr-3 text-sm"
                [ngClass]="{ 'sm:pl-0': isFirst }"
              >
                <!-- Read Mode cells -->
                @if (editRowId !== item._id) {
                  @switch (col.type) {
                    @case ("text") {
                      <cap-text-cell
                        [text]="item[col.key]"
                        [isLink]="col.key === 'url'"
                      ></cap-text-cell>
                    }
                    @case ("description") {
                      <cap-text-cell [text]="item[col.key]"></cap-text-cell>
                    }
                    @case ("radio") {
                      <cap-text-cell [text]="item[col.key]"></cap-text-cell>
                    }
                    @case ("list") {
                      <cap-text-cell
                        [text]="
                          col.key === 'weight'
                            ? convertWeight(item[col.key])
                            : col.key === 'standardId' ||
                                col.key === 'sourceId' ||
                                col.key === 'vendorId'
                              ? (getEntityRowNameById(col.key, item[col.key])
                                | async)
                              : item[col.key]
                        "
                      ></cap-text-cell>
                    }
                    @case ("button") {
                      <cap-edit-button-cell
                        (buttonClicked)="onButtonClicked(item._id, col.key)"
                        [label]="col.label"
                      ></cap-edit-button-cell>
                    }
                    @case ("status") {
                      <cap-status-cell
                        [status]="item[col.key]"
                        [props]="col.options!"
                      ></cap-status-cell>
                    }
                    @case ("checkbox") {
                      <cap-status-cell
                        [status]="item[col.key]"
                        [props]="
                          col.options ?? [
                            { label: 'Positive', value: true },
                            { label: 'Negative', value: false }
                          ]
                        "
                      ></cap-status-cell>
                    }
                    @case ("badge") {
                      <cap-badge-cell [badges]="item[col.key]"></cap-badge-cell>
                    }
                    @case ("date") {
                      <cap-date-cell [date]="item[col.key]"></cap-date-cell>
                    }
                    @case ("cost") {
                      <cap-cost-cell [value]="item[col.key]"></cap-cost-cell>
                    }
                    <!-- Other column types -->
                    @default {
                      <p></p>
                    }
                  }
                }
                <!-- Edit Mode cells -->
                @if (editRowId === item._id) {
                  @switch (col.type) {
                    @case ("text") {
                      @if (
                        (metadata.title === "Security Controls" &&
                          col.key === "version") ||
                        col.key === "subVersion"
                      ) {
                        <cap-edit-text-cell
                          [isSecurityControl]="true"
                          [text]="item[col.key]"
                          (textChange)="
                            onCellValueChange($event, item._id, col.key)
                          "
                        ></cap-edit-text-cell>
                      } @else {
                        <cap-edit-text-cell
                          [isLink]="col.key === 'url'"
                          [text]="item[col.key]"
                          (textChange)="
                            onCellValueChange($event, item._id, col.key)
                          "
                        ></cap-edit-text-cell>
                      }
                    }
                    @case ("description") {
                      <cap-edit-text-area-cell
                        [isSecurityControl]="
                          metadata.title === 'Security Controls'
                        "
                        [description]="item[col.key]"
                        (descriptionChange)="
                          onCellValueChange($event, item._id, col.key)
                        "
                      ></cap-edit-text-area-cell>
                    }
                    @case ("radio") {
                      <cap-edit-radio-cell
                        [options]="col.options!"
                        [selectedOption]="item[col.key]"
                        [groupName]="col.key"
                        (optionChange)="
                          onCellValueChange($event, item._id, col.key)
                        "
                      ></cap-edit-radio-cell>
                    }
                    @case ("list") {
                      @if (
                        metadata.title === "Security Controls" &&
                        col.key === "standardId"
                      ) {
                        <cap-edit-list-cell
                          [isSecurityControl]="true"
                          [options]="col.options!"
                          [selectedOption]="item[col.key]"
                          (optionChange)="
                            onCellValueChange($event, item._id, col.key)
                          "
                        ></cap-edit-list-cell>
                      } @else {
                        <cap-edit-list-cell
                          [options]="col.options!"
                          [selectedOption]="item[col.key]"
                          (optionChange)="
                            onCellValueChange($event, item._id, col.key)
                          "
                        ></cap-edit-list-cell>
                      }
                    }
                    @case ("button") {
                      <cap-edit-button-cell
                        (buttonClicked)="onButtonClicked(item._id, col.key)"
                        [label]="col.label"
                        [disabled]="false"
                      ></cap-edit-button-cell>
                    }
                    @case ("status") {
                      <cap-edit-status-cell
                        [options]="col.options!"
                        [groupName]="col.key"
                        [selectedOption]="item[col.key]"
                        (optionChange)="
                          onCellValueChange($event, item._id, col.key)
                        "
                      ></cap-edit-status-cell>
                    }
                    @case ("checkbox") {
                      <cap-edit-checkbox-cell
                        [isChecked]="item[col.key]"
                        [groupName]="item._id + '-' + col.key"
                        [label]="'Is Positive'"
                        (checkChange)="
                          onCellValueChange($event, item._id, col.key)
                        "
                      ></cap-edit-checkbox-cell>
                    }
                    @case ("badge") {
                      <cap-edit-badge-cell
                        [suggestedBadges]="
                          (suggestedStandardGroupings | async)!
                        "
                        [badges]="item[col.key] ?? []"
                        (badgesChange)="
                          onCellValueChange($event, item._id, col.key)
                        "
                      ></cap-edit-badge-cell>
                    }
                    @case ("date") {
                      <cap-date-cell [date]="item[col.key]"></cap-date-cell>
                    }
                    @case ("cost") {
                      <cap-edit-status-cell
                        [options]="col.options!"
                        [groupName]="col.key"
                        [selectedOption]="item[col.key]"
                        (optionChange)="
                          onCellValueChange($event, item._id, col.key)
                        "
                      ></cap-edit-status-cell>
                    }
                    <!-- Other column types -->
                    @default {
                      <p></p>
                    }
                  }
                }
              </td>
            }
            <td
              class="relative h-16 flex justify-end items-center gap-x-4 whitespace-nowrap py-3 pl-3 pr-4 text-sm font-medium sm:pr-0"
            >
              <!--Action Buttons -->
              @if (editRowId !== item._id) {
                <button
                  type="button"
                  (click)="onUpdateClicked(item._id)"
                  class="text-indigo-600 hover:text-indigo-800 dark:text-indigo-500 dark:hover:text-indigo-400"
                >
                  Edit
                </button>
                <button
                  type="button"
                  (click)="onDeleteClicked(item)"
                  class="text-red-600 hover:text-red-800 dark:text-red-500 dark:hover:text-red-400 mr-2"
                >
                  Remove
                </button>
              }

              <!-- Edit Mode Actions -->
              @if (editRowId === item._id) {
                <button
                  type="button"
                  (click)="confirmRowEdit()"
                  class="rounded-full p-1 flex justify-center items-center bg-gray-100 hover:bg-green-500 dark:bg-gray-700 dark:hover:bg-green-500 text-gray-800 hover:text-gray-50 dark:text-gray-50 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                >
                  <span class="material-symbols-outlined"> check </span>
                </button>
                <button
                  type="button"
                  (click)="cancelRowEdit()"
                  class="rounded-full p-1 flex justify-center bg-gray-100 hover:bg-primary-500 dark:bg-gray-700 dark:hover:bg-primary-500 text-gray-800 hover:text-gray-50 dark:text-gray-50 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                >
                  <span class="material-symbols-outlined"> close </span>
                </button>
              }
            </td>
          </tr>
          @if (item._id === expandedRowId) {
            <tr>
              <td [attr.colspan]="(columns$ | async)!.length + 2">
                <cap-edit-accordion-section
                  [metadata]="accordionMetadata"
                  [columns]="accordionColumns"
                  [data]="(accordionData$ | async)!"
                  (onEntityCreated)="onAccordionEntityCreated.emit($event)"
                  (onEntityUpdated)="onAccordionEntityUpdated.emit($event)"
                  (onEntityDeleted)="onAccordionEntityDeleted.emit($event)"
                ></cap-edit-accordion-section>
              </td>
            </tr>
          }
        }
      </tbody>
    }
  </table>
</div>
