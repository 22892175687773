<div
  class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8"
>
  <div>
    <h2
      class="text-base font-semibold leading-7 text-gray-800 dark:text-gray-50"
    >
      Personal Information
    </h2>
    <p class="mt-1 text-sm leading-6 text-gray-400">
      Use a permanent address where you can receive mail.
    </p>
  </div>
  <div class="md:col-span-2 flex flex-col gap-y-5">
    <cap-avatar-upload
      [avatars]="avatars$ | async"
      (avatarUpdated)="onAvatarUpdated($event)"
      (avatarRemoved)="onAvatarRemoved($event)"
    ></cap-avatar-upload>
    @if (errorMessage$ | async) {
      <cap-feedback type="error" [message]="errorMessage$ | async"></cap-feedback>
    } @if(loading$ | async) {
    <div
      class="z-20 absolute top-0 left-0 w-full h-full flex justify-center items-center bg-gray-100 opacity-50 dark:opacity-30"
    >
      <cap-spinner class="z-50" [size]="16"></cap-spinner>
    </div>
    }
    <cap-profile-form
      [formInput]="userFormInput"
      (profileFormChanged)="onProfileChanged($event)"
    ></cap-profile-form>
    <div class="mt-8 flex">
      <button
        type="submit"
        [disabled]="!(profileForm$ | async)?.valid"
        (click)="onProfileFormSubmit()"
        class="flex justify-center items-center rounded-md bg-primary-600 hover:bg-primary-500 disabled:bg-primary-300 dark:disabled:bg-primary-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primatext-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
      >
        Update Profile
      </button>
    </div>
  </div>
</div>
