import { Injectable } from '@angular/core';
import { getCountryCallingCode, getCountries } from 'libphonenumber-js/min';
import * as i18n from 'i18n-iso-countries';
import * as english from 'i18n-iso-countries/langs/en.json';

export interface ICountryPhoneCode {
  countryCode: string;
  countryName: string | undefined;
  phoneCode: string;
}

@Injectable({
  providedIn: 'root',
})
export class CountryPhoneCodeService {
  constructor() {
    i18n.registerLocale(english);
  }
  getCountryPhoneCodes(): ICountryPhoneCode[] {
    const countries = getCountries();
    const phoneCodesSet = new Set();

    return countries
      .map((countryCode) => ({
        phoneCode: getCountryCallingCode(countryCode),
        countryCode: countryCode,
        countryName:
          typeof i18n.getName(countryCode, 'en') === 'object'
            ? i18n.getName(countryCode, 'en')?.[0]
            : i18n.getName(countryCode, 'en'),
      }))
      .filter((obj) => obj.countryName !== undefined)
      .filter((obj) => {
        const isDuplicate = phoneCodesSet.has(obj.countryCode);
        phoneCodesSet.add(obj.countryCode); // Add the phone code to the Set
        return !isDuplicate; // Filter out if it's a duplicate
      })
      .sort((a, b) => +a.phoneCode - +b.phoneCode);
  }

}
