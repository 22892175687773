import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { IService } from 'src/app/core/models/services.model';
import { ServicesAPIActions } from './services.actions';

export interface Pagination {
  page: number;
  pageSize: number;
  totalPages: number;
  totalCount: number;
}
export interface ServicesState extends EntityState<IService> {
  errorMessage: string | null;
  pagination: Pagination;
}

export const adapter: EntityAdapter<IService> = createEntityAdapter<IService>({
  selectId: (service: IService) => service._id,
});

export const initialState: ServicesState = adapter.getInitialState({
  errorMessage: null,
  pagination: {
    page: 1,
    pageSize: 10,
    totalPages: 0,
    totalCount: 0,
  },
});

const servicesReducer = createReducer(
  initialState,
  on(ServicesAPIActions.getServicesSuccess, (state, { services, pagination }) =>
    adapter.setAll(services, { ...state, errorMessage: null, pagination }),
  ),
  on(ServicesAPIActions.getServicesFailure, (state, { message }) => ({
    ...adapter.removeAll(state),
    errorMessage: message,
  })),
  on(ServicesAPIActions.getServicesByIdSuccess, (state, { service }) =>
    adapter.upsertOne(service, state),
  ),
  on(ServicesAPIActions.getServicesByIdFailure, (state, { message }) => ({
    ...state,
    errorMessage: message,
  })),
  on(ServicesAPIActions.createServiceSuccess, (state, { service }) =>
    adapter.addOne(service, state),
  ),
  on(ServicesAPIActions.createServiceFailure, (state, { message }) => ({
    ...state,
    errorMessage: message,
  })),
  on(ServicesAPIActions.updateServiceSuccess, (state, { service }) =>
    adapter.upsertOne(service, state),
  ),
  on(ServicesAPIActions.updateServiceFailure, (state, { message }) => ({
    ...state,
    errorMessage: message,
  })),
  on(ServicesAPIActions.removeServiceSuccess, (state, { serviceId }) =>
    adapter.removeOne(serviceId, state),
  ),
  on(ServicesAPIActions.removeServiceFailure, (state, { message }) => ({
    ...state,
    errorMessage: message,
  })),
);

export const servicesFeature = createFeature({
  name: 'services',
  reducer: servicesReducer,
  extraSelectors: ({ selectServicesState, selectEntities }) => ({
    ...adapter.getSelectors(selectServicesState),
    selectServiceById: (id: string) =>
      createSelector(selectEntities, (entities) => entities[id]),
  }),
});

export const {
  name,
  reducer,
  selectServicesState,
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  selectServiceById,
} = servicesFeature;
