<ng-container class="max-h-dvh">
  <div class="h-full w-full">
    @if ((isLoadingAdminData | async) || isLoadingCarousel) {
      <div class="h-dvh">
        <div class="w-full h-full flex items-center justify-center">
          <p-progressSpinner class="w-20 h-20"></p-progressSpinner>
        </div>
      </div>
    } @else {
      <ng-container class="h-dvh">
        <ng-container class="h-full w-full">
          <div class="w-full mt-2">
            <p-carousel
              [value]="carouselItems"
              [numVisible]="6"
              [numScroll]="3"
              [circular]="false"
            >
              <ng-template let-item pTemplate="item">
                <div *ngIf="item.category">
                  <div
                    class="w-fit h-32 border-2 mr-1 rounded-2xl cursor-pointer transition-colors duration-300 ease-in-out hover:bg-gray-100 hover:border-white hover:shadow-default relative"
                  >
                    <div class="relative">
                      <img
                        [src]="
                          item.category.avatars.length > 0
                            ? item.category.avatars[
                                item.category.avatars.length - 1
                              ].data
                            : 'assets/images/empty-avatar.jpg'
                        "
                        [alt]="item.category.name"
                        class="w-40 h-20 rounded-xl"
                      />
                      <div
                        class="grid grid-cols-2 grid-rows-2 gap-1 absolute inset-0"
                      >
                        <div class="relative w-full h-full">
                          <p-knob
                            (click)="onClickCategory(item.lowEntities)"
                            [ngModel]="item.low"
                            [size]="35"
                            class="absolute top-0 left-0 bg-white dark:bg-slate-900 border-2 border-white rounded-full"
                            readonly="true"
                            [valueColor]="getScoreColor(item.low)"
                            [pTooltip]="'low'"
                          ></p-knob>
                        </div>
                        <div class="relative w-full h-full">
                          <p-knob
                            (click)="onClickCategory(item.mediumEntities)"
                            [ngModel]="item.medium"
                            [size]="35"
                            class="absolute top-0 right-0 bg-white dark:bg-slate-900 border-2 border-white rounded-full"
                            readonly="true"
                            [valueColor]="getScoreColor(item.medium)"
                            [pTooltip]="'medium'"
                          ></p-knob>
                        </div>
                        <div class="relative w-full h-full">
                          <p-knob
                            (click)="onClickCategory(item.highEntities)"
                            [ngModel]="item.high"
                            [size]="35"
                            class="absolute bottom-0 left-0 bg-white dark:bg-slate-900 border-2 border-white rounded-full"
                            readonly="true"
                            [valueColor]="getScoreColor(item.high)"
                            [pTooltip]="'high'"
                          ></p-knob>
                        </div>
                        <div class="relative w-full h-full">
                          <p-knob
                            (click)="onClickCategory(item.unansweredEntities)"
                            [ngModel]="item.unanswered"
                            [size]="35"
                            class="absolute bottom-0 right-0 bg-white dark:bg-slate-900 border-2 border-white rounded-full"
                            readonly="true"
                            [valueColor]="'gray'"
                            [pTooltip]="'unanswered'"
                          ></p-knob>
                        </div>
                      </div>
                    </div>
                    <div
                      class="flex justify-center w-full font-light text-xs px-1 mt-2"
                    >
                      <p class="dark:text-white text-center">
                        {{
                          item.category.name.length > 25
                            ? item.category.name.slice(0, 20) + "..."
                            : item.category.name
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </ng-template>
            </p-carousel>
          </div>
          <div>
            <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
              <div
                class="overflow-hidden rounded-lg bg-white dark:bg-slate-800 px-4 py-5 shadow sm:p-6"
              >
                <dt
                  class="truncate text-sm font-medium text-gray-500 dark:text-white"
                >
                  Total Entities
                </dt>
                <dd
                  class="mt-1 text-3xl font-semibold tracking-tight text-gray-900 dark:text-white"
                >
                  {{
                    isSysAdmin
                      ? (currentAssessmentStats.totalUsers
                          ? currentAssessmentStats.totalUsers
                          : 0) +
                        (currentAssessmentStats.totalStaff
                          ? currentAssessmentStats.totalStaff
                          : 0) +
                        (currentAssessmentStats.totalMangers
                          ? currentAssessmentStats.totalMangers
                          : 0) +
                        (currentAssessmentStats.totalAdmins
                          ? currentAssessmentStats.totalAdmins
                          : 0) +
                        (currentAssessmentStats.totalSubOrganizations
                          ? currentAssessmentStats.totalSubOrganizations
                          : 0)
                      : currentAssessmentStats.totalUsers +
                        currentAssessmentStats.totalSubOrganizations
                  }}
                </dd>
              </div>
              <div
                class="overflow-hidden rounded-lg bg-white dark:bg-slate-800 px-4 py-5 shadow sm:p-6"
              >
                <dt
                  class="truncate text-sm font-medium text-gray-500 dark:text-white"
                >
                  Individuals
                </dt>
                <dd
                  class="mt-1 text-3xl font-semibold tracking-tight text-gray-900 dark:text-white"
                >
                  {{ currentAssessmentStats.totalUsers }}
                </dd>
              </div>
              <div
                class="overflow-hidden rounded-lg bg-white dark:bg-slate-800 px-4 py-5 shadow sm:p-6"
              >
                <dt
                  class="truncate text-sm font-medium text-gray-500 dark:text-white"
                >
                  Organizations
                </dt>
                <dd
                  class="mt-1 text-3xl font-semibold tracking-tight text-gray-900 dark:text-white"
                >
                  {{ currentAssessmentStats.totalSubOrganizations }}
                </dd>
              </div>
            </dl>
            <dl
              class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3"
              *ngIf="isSysAdmin"
            >
              <div
                class="overflow-hidden rounded-lg bg-white dark:bg-slate-800 px-4 py-5 shadow sm:p-6"
              >
                <dt
                  class="truncate text-sm font-medium text-gray-500 dark:text-white"
                >
                  Organization Admins
                </dt>
                <dd
                  class="mt-1 text-3xl font-semibold tracking-tight text-gray-900 dark:text-white"
                >
                  {{ currentAssessmentStats.totalAdmins }}
                </dd>
              </div>
              <div
                class="overflow-hidden rounded-lg bg-white dark:bg-slate-800 px-4 py-5 shadow sm:p-6"
              >
                <dt
                  class="truncate text-sm font-medium text-gray-500 dark:text-white"
                >
                  Organization Managers
                </dt>
                <dd
                  class="mt-1 text-3xl font-semibold tracking-tight text-gray-900 dark:text-white"
                >
                  {{ currentAssessmentStats.totalMangers }}
                </dd>
              </div>
              <div
                class="overflow-hidden rounded-lg bg-white dark:bg-slate-800 px-4 py-5 shadow sm:p-6"
              >
                <dt
                  class="truncate text-sm font-medium text-gray-500 dark:text-white"
                >
                  Organization Staff
                </dt>
                <dd
                  class="mt-1 text-3xl font-semibold tracking-tight text-gray-900 dark:text-white"
                >
                  {{ currentAssessmentStats.totalStaff }}
                </dd>
              </div>
            </dl>
          </div>
          <div class="w-full mt-6">
            <p-table
              [value]="filteredTableData"
              [tableStyle]="{ 'min-width': '60rem' }"
              [paginator]="true"
              [rows]="10"
              [rowsPerPageOptions]="[5, 10, 20]"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th pSortableColumn="entityName">
                    <span class="flex gap-2">
                      Name <p-sortIcon field="entityName" />
                    </span>
                  </th>
                  <th pSortableColumn="entityType">
                    <span class="flex gap-2">
                      Entity Type <p-sortIcon field="entityType" />
                    </span>
                  </th>
                  <th pSortableColumn="score">
                    <span class="flex gap-2">
                      Score <p-sortIcon field="score"
                    /></span>
                  </th>
                  <th pSortableColumn="categoryName">
                    <span class="flex gap-2">
                      Category <p-sortIcon field="categoryName"
                    /></span>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-assessment>
                <tr>
                  <td class="flex gap-3">
                    <span>
                      <img
                        [src]="
                          assessment.avatar
                            ? assessment.avatar.data
                            : 'assets/images/empty-avatar.jpg'
                        "
                        class="w-6 h-6 rounded-full"
                      /> </span
                    >{{ assessment.entityName }}
                  </td>
                  <td>{{ assessment.entityType }}</td>
                  <td>{{ assessment.score.toFixed(0) }} %</td>
                  <td>
                    {{ assessment.categoryName }}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </ng-container>
      </ng-container>
    }
  </div>
</ng-container>
