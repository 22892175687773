import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'cap-pagination',
  standalone: true,
  imports: [],
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.scss',
})
export class PaginationComponent implements OnInit {
  @Input() current_page = 0;
  @Input() pageSize = 0;
  @Input() totalItems = 0;
  @Input() totalPages = 0;
  @Input() disableNext = false;
  @Output() pageChange = new EventEmitter<number>();

  navigateCellStyleClasses = {
    active:
      'inline-flex items-center border-t-2 border-indigo-500 px-4 pt-4 text-sm font-medium text-indigo-600',
    normal:
      'inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700',
  };
  get pages(): number[] {
    return Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }
  ngOnInit(): void {}

  getNavStyle(index: number) {
    if (this.current_page === index) {
      return this.navigateCellStyleClasses.active;
    }
    return this.navigateCellStyleClasses.normal;
  }
  goToPage(page: number) {
    this.pageChange.emit(page);
  }
  onNextPage() {
    this.pageChange.emit(this.current_page + 1);
  }
  onPreviousPage() {
    this.pageChange.emit(this.current_page - 1);
  }
}
