import { Component, inject, OnInit } from '@angular/core';
import { AuthService } from '../../core/services/auth.service';
import { Store } from '@ngrx/store';
import { SettingsAppActions } from '../../state/settings/settings.actions';
import {
  selectAllSettings,
  selectLogoutTime,
} from '../../state/settings/settings.selectors';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'cap-system-page',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './system-page.component.html',
  styleUrl: './system-page.component.scss',
})
export class SystemPageComponent implements OnInit {
  private readonly store = inject(Store);
  systemTimeout = 0;
  passwordLength = 12;
  constructor(private authService: AuthService) {}
  ngOnInit() {
    this.store.select(selectLogoutTime).subscribe((time) => {
      this.systemTimeout = time;
    });
    this.store.select(selectAllSettings).subscribe((settings) => {
      this.passwordLength = settings.minimumPasswordLength;
      this.systemTimeout = settings.logoutTime;
    });
  }

  // changeSystemTimeout($event: Event) {
  //   let newTime = Number((<HTMLInputElement>$event.target).value);
  //   this.store.dispatch(
  //     SettingsAppActions.updateSettings({
  //       settings: {
  //         logoutTime: newTime,
  //       },
  //     }),
  //   );
  // }

  updateSettings() {
    this.store.dispatch(
      SettingsAppActions.updateSettings({
        settings: {
          logoutTime: this.systemTimeout,
          minimumPasswordLength: this.passwordLength,
        },
      }),
    );
  }
}
