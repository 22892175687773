import { createActionGroup, emptyProps, props } from '@ngrx/store';

import {
  IQuestion,
  IQuestionBase,
  IQuestionOption,
} from 'src/app/core/models/questions.model';
import { Pagination } from './questions.state';

export const QuestionsAppActions = createActionGroup({
  source: 'Questions',
  events: {
    'Get Questions': emptyProps(),
    'Get Question By Id': props<{
      id: string;
    }>(),
    'Create Question': props<{ question: IQuestionBase }>(),
    'Update Question': props<{ question: IQuestion }>(),
    'Remove question': props<{ id: string }>(),
    'Get Questions By Category Id': props<{
      categoryId: string;
      page: number;
      pageSize: number;
    }>(),
    'Get Questions By Category Id Success': props<{
      questions: IQuestion[];
      pagination: Pagination;
    }>(),
    'Update Question Sort Order': props<{
      questionId: string;
      previousIndex: number;
      currentIndex: number;
    }>(),
    'Add Option To Question': props<{
      questionId: string;
      option: IQuestionOption;
    }>(),
    'Update Question Option': props<{
      questionId: string;
      option: IQuestionOption;
    }>(),
    'Remove Question Option': props<{ questionId: string; optionId: string }>(),
  },
});

export const QuestionsAPIActions = createActionGroup({
  source: 'Questions',
  events: {
    'Get Questions Success': props<{
      questions: IQuestion[];
    }>(),
    'Get Questions Failure': props<{ message: string }>(),
    'Get Questions By Id Success': props<{
      question: IQuestion;
    }>(),
    'Get Questions By Id Failure': props<{ message: string }>(),
    'Create Question Success': props<{ question: IQuestion }>(),
    'Create Question Failure': props<{ message: string }>(),
    'Update Question Success': props<{ question: IQuestion }>(),
    'Update Question Failure': props<{ message: string }>(),
    'Remove Question Success': props<{
      questionId: string;
      message: string;
    }>(),
    'Remove Question Failure': props<{ message: string }>(),
    'Get Questions By Category Id Success': props<{
      questions: IQuestion[];
      pagination: {
        page: number;
        limit: number;
        totalPages: number;
        totalCount: number;
      };
    }>(),
    'Get Questions By Category Id Failure': props<{ message: string }>(),
    'Update Question Sort Order Success': props<{ message: string }>(),
    'Update Question Sort Order Failure': props<{ message: string }>(),
    'Add Option To Question Success': props<{ question: IQuestion }>(),
    'Add Option To Question Failure': props<{ message: string }>(),
    'Update Question Option Success': props<{ question: IQuestion }>(),
    'Update Question Option Failure': props<{ message: string }>(),
    'Remove Question Option Success': props<{
      questionId: string;
      optionId: string;
      message: string;
    }>(),
    'Remove Question Option Failure': props<{ message: string }>(),
  },
});
