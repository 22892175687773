import { Store } from '@ngrx/store';
import {
  Component,
  ElementRef,
  HostListener,
  ViewChild,
  WritableSignal,
  inject,
  signal,
} from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, NgClass } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { Observable } from 'rxjs';

import { IUser } from 'src/app/core/models/user.model';
import { ThemeComponent } from '../theme/theme.component';
import { selectUser } from 'src/app/state/auth/auth.state';
import { selectCurrentUserAvatar } from 'src/app/state/auth/auth.selectors';
import { AuthAppActions } from 'src/app/state/auth/auth.actions';
import { RoleCheckDirective } from '../directives/role-check.directive';

@Component({
  selector: 'cap-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          opacity: 1,
          transform: 'translateX(0)',
        }),
      ),
      state(
        'closed',
        style({
          opacity: 0,
          transform: 'translateX(-100%)',
        }),
      ),
      transition('open => closed', animate('300ms ease-in-out')),
      transition('closed => open', animate('300ms ease-in-out')),
    ]),
  ],
  standalone: true,
  imports: [
    CommonModule,
    NgClass,
    FormsModule,
    RouterLink,
    RouterLinkActive,
    ReactiveFormsModule,
    ThemeComponent,
    RoleCheckDirective,
  ],
})
export class SidebarComponent {
  @ViewChild('profileDropdown') profileDropdown!: ElementRef;
  @ViewChild('sidebar') sidebar!: ElementRef;
  @ViewChild('openSidebarBtn') openSidebarBtn!: ElementRef;

  isLargeScreen: WritableSignal<boolean> =
    window.innerWidth >= 1024 ? signal(true) : signal(false);
  isSidebarOpen: WritableSignal<boolean> = signal(false);

  isDropdownOpen: WritableSignal<boolean> = signal(false);

  private readonly store = inject(Store);

  currentUser$: Observable<IUser | null> = this.store.select(selectUser);
  currentAvatar$ = this.store.select(selectCurrentUserAvatar);

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    window.innerWidth >= 1024
      ? this.isLargeScreen.update(() => true)
      : this.isLargeScreen.update(() => false);
    if (!this.isLargeScreen()) {
      this.closeSidebar();
    }
  }

  openSidebar() {
    this.isSidebarOpen.update(() => true);
  }

  closeSidebar() {
    this.isSidebarOpen.update(() => false);
  }

  toggleDropdown() {
    this.isDropdownOpen.update((isOpen) => !isOpen);
  }

  closeDropdown() {
    this.isDropdownOpen.update((isOpen) => false);
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (
      this.isDropdownOpen() === true &&
      !this.profileDropdown.nativeElement.contains(event.target)
    ) {
      this.closeDropdown();
    }
    if (
      this.isSidebarOpen() === true &&
      !this.sidebar.nativeElement.contains(event.target) &&
      !this.openSidebarBtn.nativeElement.contains(event.target)
    ) {
      this.closeSidebar();
    }
  }

  onLogout(): void {
    this.store.dispatch(AuthAppActions.logout());
  }
}
