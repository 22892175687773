<div
  *ngIf="text$ | async as text"
  class="text-gray-900 dark:text-gray-50 text-wrap overflow-y-auto break-words max-h-[4.5rem] w-full"
>
  <ng-container *ngIf="isQuestion; else linkOrText">
    <p>
      {{ text }}
    </p>
  </ng-container>
  <ng-template #linkOrText>
    <ng-container *ngIf="isLink; else plainText">
      <a [href]="text" target="_blank">
        <p class="text-blue-600 hover:text-blue-300">
          {{ text }}
        </p>
      </a>
    </ng-container>
    <ng-template #plainText>
      <p>
        {{ text }}
      </p>
    </ng-template>
  </ng-template>
</div>
