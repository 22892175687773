import { inject, Injectable, OnDestroy, OnInit } from '@angular/core';
import { Observable, throttle } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { IAuthResponse } from '../models/auth-response.model';
import { ILogin } from '../models/login.model';
import { IResetPassword, ISetNewPassword } from '../models/password.modal';
import { IUser } from '../models/user.model';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthAppActions } from '../../state/auth/auth.actions';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  private logoutTimer: any;
  private isTimerRunning = false;
  private logoutTime = 1000 * 60 * 15;
  private store = inject(Store);
  constructor(
    private http: HttpClient,
    private router: Router,
  ) {}

  private authChannel = new BroadcastChannel('auth_channel');

  // Unique ID for the tab
  readonly tabId = Date.now() + Math.random().toString();

  broadcastAuthEvent(event: 'login_event' | 'logout_event') {
    this.authChannel.postMessage({ event, tabId: this.tabId });
  }

  getAuthChannel(): BroadcastChannel {
    return this.authChannel;
  }

  refreshToken(): Observable<{
    access_token: string;
    token_expiration: number;
    user: IUser;
  }> {
    return this.http.get<{
      access_token: string;
      token_expiration: number;
      user: IUser;
    }>(`${environment.apiUrl}/auth/refresh-token`, {
      withCredentials: true,
    });
  }

  login(credentials: ILogin): Observable<{ user: IUser }> {
    return this.http.post<{ user: IUser }>(
      `${environment.apiUrl}/auth/login`,
      credentials,
      {
        withCredentials: true,
      },
    );
  }

  logout(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/auth/logout`, {
      withCredentials: true,
    });
  }

  setNewPassword(newPasswordObj: ISetNewPassword): Observable<{ user: IUser }> {
    return this.http.post<{ user: IUser }>(
      `${environment.apiUrl}/auth/set-password`,
      newPasswordObj,
      {
        withCredentials: true,
      },
    );
  }

  forgotPassword(email: string) {
    return this.http.post(`${environment.apiUrl}/auth/forgot-password`, {
      email,
    });
  }

  resendEmailOTP(email: string) {
    return this.http.post(`${environment.apiUrl}/auth/resend-otp`, {
      email,
    });
  }

  resetPassword(data: IResetPassword) {
    return this.http.post(`${environment.apiUrl}/auth/reset-password`, data);
  }

  generate2FAOTP(
    email: string,
  ): Observable<{ otpAuthUrl: string; otpBase32: string }> {
    return this.http.post<{ otpAuthUrl: string; otpBase32: string }>(
      `${environment.apiUrl}/auth/otp/generate`,
      {
        email,
      },
    );
  }

  verify2FAOTP(data: {
    email: string;
    token: string;
  }): Observable<IAuthResponse> {
    return this.http.post<IAuthResponse>(
      `${environment.apiUrl}/auth/otp/verify`,
      { email: data.email, token: data.token },
      {
        withCredentials: true,
      },
    );
  }

  validate2FAOTP(data: {
    email: string;
    token: string;
  }): Observable<IAuthResponse> {
    return this.http.post<IAuthResponse>(
      `${environment.apiUrl}/auth/otp/validate`,
      { email: data.email, token: data.token },
      {
        withCredentials: true,
      },
    );
  }

  reset2FAOTP(email: string): Observable<{ message: string }> {
    return this.http.post<{
      message: string;
    }>(`${environment.apiUrl}/auth/otp/reset`, { email });
  }

  verifyResetting2FAOTP(
    token: string,
  ): Observable<{
    message: string;
    email: string;
    otpAuthUrl: string;
    otpBase32: string;
  }> {
    return this.http.post<{
      message: string;
      email: string;
      otpAuthUrl: string;
      otpBase32: string;
    }>(`${environment.apiUrl}/auth/otp/verify-reset`, { token });
  }

  ngOnDestroy(): void {
    this.authChannel.close();
    clearTimeout(this.logoutTimer);
  }

  // Auto logout functionality
  set setLogoutTime(logoutTime: number) {
    this.logoutTime = logoutTime * 60 * 1000;
  }
  setLogoutTimeout(): void {
    clearTimeout(this.logoutTimer);
    this.logoutTimer = setTimeout(() => {
      this.store.dispatch(AuthAppActions.logout());
      this.router.navigate(['auth/login']);
    }, this.logoutTime);
  }

  initializeListeners(): void {
    window.onload = () => this.resetTimer(this.logoutTime);
    window.onmousemove = () => this.resetTimer(this.logoutTime);
    window.onkeypress = () => this.resetTimer(this.logoutTime);
  }

  private resetTimer(time: number): void {
    clearTimeout(this.logoutTimer);
    this.isTimerRunning = true;
    this.setLogoutTimeout();
  }
}
