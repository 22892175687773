import { createActionGroup, emptyProps, props } from '@ngrx/store';

import {
  IServiceBase,
  IService,
  IServiceSourceBase,
  IServiceSource,
  IServiceVendorBase,
  IServiceVendor,
} from 'src/app/core/models/services.model';
import { Pagination } from './services.state';

export const ServicesAppActions = createActionGroup({
  source: 'Services',
  events: {
    'Get Services': props<{ page?: number; pageSize?: number }>(),
    'Get Service By Id': props<{ id: string }>(),
    'Create Service': props<{ service: IServiceBase }>(),
    'Update Service': props<{ service: IService }>(),
    'Remove Service': props<{ id: string }>(),
    'Get Service Sources': props<{ page?: number; pageSize?: number }>(),
    'Get Service Source By Id': props<{ id: string }>(),
    'Create Service Source': props<{ serviceSource: IServiceSourceBase }>(),
    'Update Service Source': props<{ serviceSource: IServiceSource }>(),
    'Remove Service Source': props<{ id: string }>(),
    'Get Service Vendors': props<{ page?: number; pageSize?: number }>(),
    'Get Service Vendor By Id': props<{ id: string }>(),
    'Create Service Vendor': props<{ serviceVendor: IServiceVendorBase }>(),
    'Update Service Vendor': props<{ serviceVendor: IServiceVendor }>(),
    'Remove Service Vendor': props<{ id: string }>(),
  },
});

export const ServicesAPIActions = createActionGroup({
  source: 'Services',
  events: {
    'Get Services Success': props<{
      services: IService[];
      pagination: Pagination;
    }>(),
    'Get Services Failure': props<{ message: string }>(),
    'Get Services By Id Success': props<{ service: IService }>(),
    'Get Services By Id Failure': props<{ message: string }>(),
    'Create Service Success': props<{ service: IService }>(),
    'Create Service Failure': props<{ message: string }>(),
    'Update Service Success': props<{ service: IService }>(),
    'Update Service Failure': props<{ message: string }>(),
    'Remove Service Success': props<{ serviceId: string; message: string }>(),
    'Remove Service Failure': props<{ message: string }>(),
    'Get Service Sources Success': props<{
      serviceSources: IServiceSource[];
      pagination: Pagination;
    }>(),
    'Get Service Sources Failure': props<{ message: string }>(),
    'Get Service Source By Id Success': props<{
      serviceSource: IServiceSource;
    }>(),
    'Get Service Source By Id Failure': props<{ message: string }>(),
    'Create Service Source Success': props<{ serviceSource: IServiceSource }>(),
    'Create Service Source Failure': props<{ message: string }>(),
    'Update Service Source Success': props<{ serviceSource: IServiceSource }>(),
    'Update Service Source Failure': props<{ message: string }>(),
    'Remove Service Source Success': props<{
      serviceSourceId: string;
      message: string;
    }>(),
    'Remove Service Source Failure': props<{ message: string }>(),
    'Get Service Vendors Success': props<{
      serviceVendors: IServiceVendor[];
      pagination: Pagination;
    }>(),
    'Get Service Vendors Failure': props<{ message: string }>(),
    'Get Service Vendor By Id Success': props<{
      serviceVendor: IServiceVendor;
    }>(),
    'Get Service Vendor By Id Failure': props<{ message: string }>(),
    'Create Service Vendor Success': props<{ serviceVendor: IServiceVendor }>(),
    'Create Service Vendor Failure': props<{ message: string }>(),
    'Update Service Vendor Success': props<{ serviceVendor: IServiceVendor }>(),
    'Update Service Vendor Failure': props<{ message: string }>(),
    'Remove Service Vendor Success': props<{
      serviceVendorId: string;
      message: string;
    }>(),
    'Remove Service Vendor Failure': props<{ message: string }>(),
  },
});
