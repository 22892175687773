import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { SpinnerComponent } from '../../../shared/spinner/spinner.component';
import { TableComponent } from '../../../shared/table/table.component';
import { IColumn } from '../../../core/models/column.model';
import { AsyncPipe } from '@angular/common';
import { CategoryUpsertModalComponent } from '../../categories-list/category-upsert-modal/category-upsert-modal.component';
import {
  ProfilesApiActions,
  ProfilesAppActions,
} from '../../../state/profiles/profiles.actions';
import { Store } from '@ngrx/store';
import { selectProfiles } from '../../../state/profiles/profiles.selectors';
import { map, Subject, takeUntil, tap } from 'rxjs';
import { convertProfileToProfileTable } from '../../../utils/entity-formatting.util';
import { ModalActions } from '../../../state/modal/modal.actions';
import { IndividualProfileUpsertComponent } from './individual-profile-upsert/individual-profile-upsert.component';
import { selectModalVisibility } from '../../../state/modal/modal.state';
import { selectProfileById } from '../../../state/profiles/profiles.state';
import { IProfile } from '../../../core/models/profiles.model';
import { ConfirmModalComponent } from '../../../shared/confirm-modal/confirm-modal.component';
import { Actions, ofType } from '@ngrx/effects';
import { FormsModule } from '@angular/forms';
import { FeedbackComponent } from '../../../shared/feedback/feedback.component';
import { ProfileStore } from '../profile.store';

export interface IModalProfileMetadata {
  title: string;
  modalWidth: string;
  modalID: string;
  isEditMode: boolean;
  profile: IProfile | null | undefined;
}

@Component({
  selector: 'cap-individuals-profiles',
  standalone: true,
  providers: [ProfileStore],
  imports: [
    SpinnerComponent,
    TableComponent,
    AsyncPipe,
    CategoryUpsertModalComponent,
    IndividualProfileUpsertComponent,
    ConfirmModalComponent,
    FormsModule,
    FeedbackComponent,
  ],
  templateUrl: './individuals-profiles.component.html',
  styleUrl: './individuals-profiles.component.scss',
})
export class IndividualsProfilesComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  private actions$ = inject(Actions);
  searchQuery = '';
  profileDropped: any;
  private readonly store = inject(Store);
  modalMetaData!: IModalProfileMetadata;
  isLoadingProfileList = signal(true);
  profiles$ = this.store.select(selectProfiles);
  private readonly componentStore = inject(ProfileStore);
  errorMessage$ = this.componentStore.select((state) => state.errorMessage);

  readonly confirmModalData = {
    title: 'Delete Profile',
    message: 'Are you sure you want to delete this profile?',
  };
  individualProfiles$ = this.profiles$.pipe(
    map((profiles) => profiles.map(convertProfileToProfileTable)),
  );
  individualProfileColumns: IColumn[] = [
    {
      key: 'name',
      label: 'Name',
      type: 'text',
      sortable: true,
    },
    {
      key: 'categories',
      label: 'Categories',
      type: 'text',
      sortable: false,
    },
    {
      key: 'associatedIndividuals',
      label: 'Individuals',
      type: 'text',
      sortable: false,
    },
    {
      key: 'isEnabled',
      label: 'Enabled',
      type: 'button',
      sortable: false,
    },
  ];
  ngOnInit() {
    this.store.dispatch(ProfilesAppActions.getIndividualProfiles());
    this.actions$
      .pipe(
        ofType(
          ProfilesAppActions.getIndividualProfiles,
          ProfilesApiActions.getProfilesSuccess,
          ProfilesApiActions.getProfilesFailure,
        ),
        tap((action) =>
          this.isLoadingProfileList.set(
            action.type === ProfilesAppActions.getIndividualProfiles.type,
          ),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  openCreateProfileModal() {
    this.modalMetaData = {
      modalID: 'create-individual-profile',
      modalWidth: 'sm:max-w-7xl',
      title: 'Create Individual Profile',
      isEditMode: false,
      profile: null,
    };
    this.store.dispatch(ModalActions.open({ id: 'create-individual-profile' }));
  }

  openConfirmationModel(profile: any) {
    this.profileDropped = profile;
    this.store.dispatch(ModalActions.open({ id: 'confirm-delete-profile' }));
  }

  openUpdateProfileModal(id: string) {
    this.store
      .select(selectProfileById(id))
      .pipe(
        map(
          (profile) =>
            (this.modalMetaData = {
              modalID: 'update-individual-profile',
              modalWidth: 'sm:max-w-7xl',
              title: 'Update Individual Profile',
              isEditMode: true,
              profile,
            }),
          takeUntil(this.destroy$),
        ),
      )
      .subscribe();
    this.store.dispatch(ModalActions.open({ id: 'update-individual-profile' }));
  }

  protected readonly selectModalVisibility = selectModalVisibility;

  selectModalVisibilityById(profileModal: string) {
    return this.store.select(this.selectModalVisibility(profileModal));
  }

  removeProfile() {
    if (this.profileDropped) {
      this.store.dispatch(
        ProfilesAppActions.deleteProfile({ id: this.profileDropped._id }),
      );
      this.store.dispatch(ProfilesAppActions.getIndividualProfiles());
    }
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  UpdateEnableStatus($event: any) {
    const { effectedEntity, value } = $event;
    //use effectedEnitity to dispatch the enable profile actions
    this.store.dispatch(
      ProfilesAppActions.enableProfile({
        id: effectedEntity,
        isEnabled: value,
      }),
    );
  }

  searchProfiles() {
    this.store.dispatch(
      ProfilesAppActions.searchProfiles({
        search: this.searchQuery,
        profileType: 'individual',
      }),
    );
  }
}
