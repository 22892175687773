import { importProvidersFrom } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
  BrowserAnimationsModule,
  provideAnimations,
} from '@angular/platform-browser/animations';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { provideState, provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import { environment } from 'src/environments/environment';
import { routes } from './app.routes';
import { provideCore } from './core/core';
import { authTokenInterceptor } from './core/interceptors/token.interceptor';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { alertsFeature } from './state/alert/alert.state';
import { modalsFeature } from './state/modal/modal.state';
import { authFeature } from './state/auth/auth.state';
import { rolesFeature } from './state/roles/roles.state';
import { organizationsFeature } from './state/organizations/organizations.state';
import { usersFeature } from './state/users/users.state';
import { categoriesFeature } from './state/categories/categories.state';
import { questionsFeature } from './state/questions/questions.state';
import { actionItemsFeature } from './state/action-items/action-items.state';
import { securityControlsFeature } from './state/security-controls/security-controls.state';
import { standardsFeature } from './state/security-controls/standards.state';
import { servicesFeature } from './state/services/services.state';
import { serviceSourcesFeature } from './state/services/service-sources.state';
import { serviceVendorsFeature } from './state/services/service-vendors.state';
import { profilesFeature } from './state/profiles/profiles.state';
import { AssessmentsFeature } from './state/assessments/assessments.state';
import { settingsFeature } from './state/settings/settings.state';
import * as AlertsEffects from './state/alert/alert.effects';
import * as AuthEffects from './state/auth/auth.effects';
import * as OrganizationsEffects from './state/organizations/organizations.effects';
import * as RolesEffects from './state/roles/roles.effects';
import * as UsersEffects from './state/users/users.effects';
import * as CategoriesEffects from './state/categories/categories.effects';
import * as QuestionsEffects from './state/questions/questions.effects';
import * as ActionItemsEffectts from './state/action-items/action-items.effects';
import * as SecurityControlsEffects from './state/security-controls/security-controls.effects';
import * as ServicesEffects from './state/services/services.effects';
import * as ProfilesEffects from './state/profiles/profiles.effects';
import * as AssessmentsEffects from './state/assessments/assessments.effects';
import * as SettingsEffects from './state/settings/settings.effect';
export const appConfig = {
  _providers: [
    importProvidersFrom(
      BrowserModule,
      BrowserAnimationsModule,
      FormsModule,
      ReactiveFormsModule,
    ),
    provideHttpClient(withInterceptors([authTokenInterceptor])),
    provideCore(),
    provideAnimations(),
    provideAnimationsAsync(),
    provideRouter(
      routes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'top',
      }),
    ),
    provideStore({ router: routerReducer }),
    provideRouterStore(),
    provideState(authFeature),
    provideState(alertsFeature),
    provideState(modalsFeature),
    provideState(rolesFeature),
    provideState(organizationsFeature),
    provideState(usersFeature),
    provideState(categoriesFeature),
    provideState(questionsFeature),
    provideState(actionItemsFeature),
    provideState(securityControlsFeature),
    provideState(standardsFeature),
    provideState(servicesFeature),
    provideState(serviceSourcesFeature),
    provideState(serviceVendorsFeature),
    provideState(profilesFeature),
    provideState(AssessmentsFeature),
    provideState(settingsFeature),
    provideEffects(
      AlertsEffects,
      AuthEffects,
      OrganizationsEffects,
      RolesEffects,
      UsersEffects,
      CategoriesEffects,
      QuestionsEffects,
      ActionItemsEffectts,
      SecurityControlsEffects,
      ServicesEffects,
      ProfilesEffects,
      AssessmentsEffects,
      SettingsEffects,
    ),
    provideStoreDevtools({
      maxAge: 50,
      logOnly: environment.production,
      trace: true,
      traceLimit: 50,
      autoPause: true,
    }),
  ],
  get providers() {
    return this._providers;
  },
  set providers(value) {
    this._providers = value;
  },
};
