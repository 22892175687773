<div class="w-full space-y-5">
  @if (errorMessage$ | async) {
    <div class="mt-10">
      <cap-feedback
        type="error"
        [message]="errorMessage$ | async"
      ></cap-feedback>
    </div>
  }
  @if (loading$ | async) {
    <div
      class="z-20 absolute top-0 left-0 w-full h-full flex justify-center items-center bg-gray-100 opacity-50 dark:opacity-30"
    >
      <cap-spinner class="z-50" [size]="16"></cap-spinner>
    </div>
  }
  <div class="flow-root">
    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        @if (listLoading$ | async) {
          <div
            class="rounded-md mt-10 w-full"
            *ngFor="let item of [].constructor(5); let i = index"
          >
            <div class="animate-pulse flex space-x-4">
              <div
                class="rounded-full bg-gray-200 dark:bg-slate-700 h-10 w-10"
              ></div>
              <div class="flex-1 space-y-6 py-1">
                <div class="h-2 bg-gray-200 dark:bg-slate-700 rounded"></div>
                <div class="space-y-3">
                  <div class="grid grid-cols-3 gap-4">
                    <div
                      class="h-2 bg-gray-200 dark:bg-slate-700 rounded col-span-2"
                    ></div>
                    <div
                      class="h-2 bg-gray-200 dark:bg-slate-700 rounded col-span-1"
                    ></div>
                  </div>
                  <div class="h-2 bg-gray-200 dark:bg-slate-700 rounded"></div>
                </div>
              </div>
            </div>
          </div>
        } @else {
          <cap-editable-table
            [metadata]="servicesTableMetaData"
            [columns]="serviceTableColumns"
            [data]="(services$ | async)!"
            (onEntityCreated)="onServiceCreated($event)"
            (onEntityUpdated)="onServiceUpdated($event)"
            (onEntityDeleted)="openConfirmationModal($event)"
          ></cap-editable-table>
          <cap-pagination
            [current_page]="page"
            [totalItems]="totalCount"
            [totalPages]="totalPages"
            [pageSize]="pageSize"
            (pageChange)="onPageChange($event)"
            [disableNext]="page == totalPages"
          ></cap-pagination>
        }
      </div>
    </div>
  </div>

  <cap-confirm-modal
    [modalID]="'confirm-removing-service'"
    [data]="confirmModalData"
    (confirmEvent)="removeService()"
  ></cap-confirm-modal>
</div>
