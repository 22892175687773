import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { ICategoryBase, ICategory } from 'src/app/core/models/categories.model';
import { IAvatar } from 'src/app/core/models/user.model';

export const CategoriesAppActions = createActionGroup({
  source: 'Categories',
  events: {
    'Get Categories': props<{ page: number; pageSize: number }>(),

    'Get Categories By Type': props<{
      categoryType: string;
      page: number;
      limit: number;
    }>(),
    'Get Category By Id': props<{ id: string }>(),
    'Search Categories': props<{
      page: number;
      pageSize: number;
      categoryType: string;
      search: string;
    }>(),
    'Create Category': props<{ category: ICategoryBase }>(),
    'Update Category': props<{ category: ICategory }>(),
    'Toggle Category Status': props<{
      categoryId: string;
      isActive: boolean;
    }>(),
    'Remove Category': props<{ id: string }>(),
    'Update Category Avatar': props<{
      categoryId: string;
      avatar: IAvatar;
    }>(),
    'Remove Category Avatar': props<{
      categoryId: string;
      avatar: IAvatar;
    }>(),
    'Update Category Sort Order': props<{
      categoryId: string;
      previousIndex: number;
      currentIndex: number;
    }>(),
  },
});

export const CategoriesAPIActions = createActionGroup({
  source: 'Categories',
  events: {
    'Get Categories Success': props<{
      categories: ICategory[];
      pagination: {
        page: number;
        limit: number;
        totalPages: number;
        totalCount: number;
      };
    }>(),
    'Get Categories Failure': props<{ message: string }>(),
    'Get Categories By Type Success': props<{
      categories: ICategory[];
      pagination: {
        page: number;
        limit: number;
        totalPages: number;
        totalCount: number;
      };
    }>(),
    'Get Categories By Type Failure': props<{ message: string }>(),
    'Get Category By Id Success': props<{ category: ICategory }>(),
    'Get Category By Id Failure': props<{ message: string }>(),
    'Search Categories Success': props<{
      categories: ICategory[];
      pagination: {
        page: number;
        limit: number;
        totalPages: number;
        totalCount: number;
      };
    }>(),
    'Search Categories Failure': props<{ message: string }>(),
    'Create Category Success': props<{ category: ICategory }>(),
    'Create Category Failure': props<{ message: string }>(),
    'Update Category Success': props<{ category: ICategory }>(),
    'Update Category Failure': props<{ message: string }>(),
    'Toggle Category Status Success': props<{
      categoryId: string;
      isActive: boolean;
    }>(),
    'Toggle Category Status Failure': props<{ message: string }>(),
    'Remove Category Success': props<{ categoryId: string; message: string }>(),
    'Remove Category Failure': props<{ message: string }>(),
    'Update Category Avatar Success': props<{
      categoryId: string;
      avatars: IAvatar[];
    }>(),
    'Update Category Avatar Failure': props<{ message: string }>(),
    'Remove Category Avatar Success': props<{
      categoryId: string;
      avatar: IAvatar;
    }>(),
    'Remove Category Avatar Failure': props<{ message: string }>(),
    'Update Category Sort Order Success': props<{ message: string }>(),
    'Update Category Sort Order Failure': props<{ message: string }>(),
  },
});
