import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { IActionItem } from 'src/app/core/models/action-items.model';
import { ActionItemsAPIActions } from './action-items.actions';

export interface ActionItemsState extends EntityState<IActionItem> {
  errorMessage: string | null;
}

export const adapter: EntityAdapter<IActionItem> =
  createEntityAdapter<IActionItem>({
    selectId: (actionItem: IActionItem) => actionItem._id,
  });

export const initialState: ActionItemsState = adapter.getInitialState({
  errorMessage: null,
});

const actionItemsReducer = createReducer(
  initialState,
  on(ActionItemsAPIActions.getActionItemsSuccess, (state, { actionItems }) => {
    return adapter.setAll(actionItems, { ...state, errorMessage: null });
  }),
  on(ActionItemsAPIActions.getActionItemsFailure, (state, { message }) => {
    return { ...adapter.removeAll(state), errorMessage: message };
  }),
  on(
    ActionItemsAPIActions.getActionItemByIdSuccess,
    (state, { actionItem }) => {
      return adapter.upsertOne(actionItem, state);
    },
  ),
  on(ActionItemsAPIActions.getActionItemByIdFailure, (state, { message }) => {
    return { ...state, errorMessage: message };
  }),
  on(ActionItemsAPIActions.createActionItemSuccess, (state, { actionItem }) => {
    return adapter.addOne(actionItem, state);
  }),
  on(ActionItemsAPIActions.createActionItemFailure, (state, { message }) => {
    return { ...state, errorMessage: message };
  }),
  on(ActionItemsAPIActions.updateActionItemSuccess, (state, { actionItem }) => {
    return adapter.upsertOne(actionItem, state);
  }),
  on(ActionItemsAPIActions.updateActionItemFailure, (state, { message }) => {
    return { ...state, errorMessage: message };
  }),
  on(
    ActionItemsAPIActions.removeActionItemSuccess,
    (state, { actionItemId }) => {
      return adapter.removeOne(actionItemId, state);
    },
  ),
  on(ActionItemsAPIActions.removeActionItemFailure, (state, { message }) => {
    return { ...state, errorMessage: message };
  }),
  on(
    ActionItemsAPIActions.getActionItemsByQuestionIdSuccess,
    (state, { actionItems }) => {
      return adapter.upsertMany(actionItems, state);
    },
  ),
  on(
    ActionItemsAPIActions.getActionItemsByQuestionIdFailure,
    (state, { message }) => {
      return { ...state, errorMessage: message };
    },
  ),
  on(
    ActionItemsAPIActions.updateActionItemControlsSuccess,
    (state, { actionItem }) => {
      return adapter.upsertOne(actionItem, state);
    },
  ),
  on(
    ActionItemsAPIActions.updateActionItemControlsFailure,
    (state, { message }) => {
      return { ...state, errorMessage: message };
    },
  ),
  on(
    ActionItemsAPIActions.updateActionItemServicesSuccess,
    (state, { actionItem }) => {
      return adapter.upsertOne(actionItem, state);
    },
  ),
  on(
    ActionItemsAPIActions.updateActionItemServicesFailure,
    (state, { message }) => {
      return { ...state, errorMessage: message };
    },
  ),
  on(
    ActionItemsAPIActions.uploadActionItemDocsSuccess,
    (state, { actionItemId, docs }) => {
      return adapter.updateOne({ id: actionItemId, changes: { docs } }, state);
    },
  ),
  on(
    ActionItemsAPIActions.uploadActionItemDocsFailure,
    (state, { message }) => {
      return { ...state, errorMessage: message };
    },
  ),
  on(
    ActionItemsAPIActions.removeActionItemDocSuccess,
    (state, { actionItemId, doc }) => {
      const actionItem = state.entities[actionItemId];
      if (!actionItem) {
        return state;
      }

      const updatedDocs = actionItem.docs.filter((dc) => dc._id !== doc._id);
      return adapter.updateOne(
        { id: actionItemId, changes: { docs: updatedDocs } },
        state,
      );
    },
  ),
  on(ActionItemsAPIActions.removeActionItemDocFailure, (state, { message }) => {
    return { ...state, errorMessage: message };
  }),
);

export const actionItemsFeature = createFeature({
  name: 'actionItems',
  reducer: actionItemsReducer,
  extraSelectors: ({ selectActionItemsState, selectEntities }) => ({
    ...adapter.getSelectors(selectActionItemsState),
    selectActionItemById: (id: string) =>
      createSelector(selectEntities, (entities) => entities[id] ?? null),
  }),
});

export const {
  name,
  reducer,
  selectActionItemsState,
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  selectActionItemById,
} = actionItemsFeature;
