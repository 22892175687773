<div>
  <!-- Off-canvas menu for mobile -->
  <div
    class="relative lg:hidden z-50"
    role="dialog"
    aria-modal="true"
    [ngClass]="{ block: isSidebarOpen(), hidden: !isSidebarOpen() }"
  >
    <div class="fixed inset-0 bg-gray-900/80"></div>

    <div
      class="fixed inset-0 flex"
      [@openClose]="isSidebarOpen() ? 'open' : 'closed'"
    >
      <div class="relative mr-16 flex w-full max-w-xs flex-1">
        <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
          <button type="button" class="-m-2.5 p-2.5" (click)="closeSidebar()">
            <span class="sr-only">Close sidebar</span>
            <svg
              class="h-6 w-6 text-white"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <!-- Sidebar component -->
        <div
          #sidebar
          class="flex grow-1 flex-col gap-y-5 overflow-y-auto bg-white dark:bg-gray-900 px-6 pb-4"
        >
          <div class="flex h-16 shrink-0 items-center">
            <img
              class="h-12 w-full"
              src="assets/images/cw-logo-light.svg"
              alt="Your Company"
            />
          </div>
          <nav class="flex flex-1 flex-col">
            <ul role="list" class="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" class="-mx-2 space-y-1">
                  <li *capRoleCheck="['OA', 'SSA', 'SA', 'I', 'OS']">
                    <a
                      [routerLink]="['/dashboard']"
                      routerLinkActive="bg-gray-50 dark:bg-gray-800 text-primary-600 dark:text-primary-600 hover:text-primary-500 dark:hover:text-primary-500 hover:bg-gray-50 dark:hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      class="text-gray-700 dark:text-gray-300 hover:text-primary-500 dark:hover:text-primary-500 hover:bg-gray-50 dark:hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                    >
                      <svg
                        routerLinkActive="text-primary-600 hover:text-primary-500"
                        class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-primary-500 dark:group-hover:text-primary-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                        />
                      </svg>
                      Dashboard
                    </a>
                  </li>
                  <li *capRoleCheck="['OM', 'OA', 'SSA', 'SA']">
                    <a
                      [routerLink]="['/management']"
                      routerLinkActive="bg-gray-50 dark:bg-gray-800 text-primary-600 dark:text-primary-600 hover:text-primary-500 dark:hover:text-primary-500 hover:bg-gray-50 dark:hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      class="text-gray-700 dark:text-gray-300 hover:text-primary-500 dark:hover:text-primary-500 hover:bg-gray-50 dark:hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                    >
                      <span
                        routerLinkActive="text-primary-600 hover:text-primary-500"
                        class="material-symbols-outlined text-gray-400 group-hover:text-primary-500 dark:group-hover:text-primary-500"
                      >
                        manage_accounts
                      </span>
                      Management
                    </a>
                  </li>
                  <li *capRoleCheck="['SA', 'SSA']">
                    <a
                      [routerLink]="['/administration']"
                      routerLinkActive="bg-gray-50 dark:bg-gray-800 text-primary-600 dark:text-primary-600 hover:text-primary-500 dark:hover:text-primary-500 hover:bg-gray-50 dark:hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      class="text-gray-700 dark:text-gray-300 hover:text-primary-500 dark:hover:text-primary-500 hover:bg-gray-50 dark:hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                    >
                      <span
                        routerLinkActive="text-primary-600 hover:text-primary-500"
                        class="material-symbols-outlined h-6 w-6 shrink-0 text-gray-400 group-hover:text-primary-500 dark:group-hover:text-primary-500"
                      >
                        admin_panel_settings
                      </span>
                      Administration
                    </a>
                  </li>
                  <li>
                    <a
                      [routerLink]="['/assessments']"
                      *capRoleCheck="['OA', 'SSA', 'SA', 'I', 'OS']"
                      routerLinkActive="bg-gray-50 dark:bg-gray-800 text-primary-600 dark:text-primary-600 hover:text-primary-500 dark:hover:text-primary-500 hover:bg-gray-50 dark:hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      class="text-gray-700 dark:text-gray-300 hover:text-primary-500 dark:hover:text-primary-500 hover:bg-gray-50 dark:hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                    >
                      <span
                        routerLinkActive="text-primary-600 hover:text-primary-500"
                        class="material-symbols-outlined h-6 w-6 shrink-0 text-gray-400 group-hover:text-primary-500 dark:group-hover:text-primary-500"
                      >
                        checklist
                      </span>
                      Assessments
                    </a>
                  </li>
                  <li>
                    <a
                      [routerLink]="['/reports']"
                      *capRoleCheck="['OA', 'SSA', 'SA', 'I', 'OS']"
                      routerLinkActive="bg-gray-50 dark:bg-gray-800 text-primary-600 dark:text-primary-600 hover:text-primary-500 dark:hover:text-primary-500 hover:bg-gray-50 dark:hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      class="text-gray-700 dark:text-gray-300 hover:text-primary-500 dark:hover:text-primary-500 hover:bg-gray-50 dark:hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                    >
                      <svg
                        routerLinkActive="text-primary-600 hover:text-primary-500"
                        class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-primary-500 dark:group-hover:text-primary-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z"
                        />
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z"
                        />
                      </svg>
                      Reports
                    </a>
                  </li>
                </ul>
              </li>
              <li class="mt-auto">
                <a
                  [routerLink]="['/settings']"
                  routerLinkActive="bg-gray-50 dark:bg-gray-800 text-primary-600 dark:text-primary-600 hover:text-primary-500 dark:hover:text-primary-500 hover:bg-gray-50 dark:hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                  class="text-gray-700 dark:text-gray-300 hover:text-primary-500 dark:hover:text-primary-500 hover:bg-gray-50 dark:hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                >
                  <svg
                    routerLinkActive="text-primary-600 hover:text-primary-500"
                    class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-primary-500 dark:group-hover:text-primary-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                  Settings
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <!-- Static sidebar for desktop -->
  <div class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-56 lg:flex-col">
    <!-- Sidebar component -->
    <div
      class="flex grow w-56 flex-col gap-y-5 overflow-y-auto border-r border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-900 px-6 pb-4"
    >
      <div class="flex h-16 shrink-0 items-center">
        <img
          class="h-12 w-full"
          src="assets/images/cw-logo-light.svg"
          alt="Your Company"
        />
      </div>
      <nav class="flex flex-1 flex-col">
        <ul role="list" class="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" class="-mx-2 space-y-1">
              <li *capRoleCheck="['OA', 'SSA', 'SA', 'I', 'OS']">
                <a
                  [routerLink]="['/dashboard']"
                  routerLinkActive="bg-gray-50 dark:bg-gray-800 text-primary-600 dark:text-primary-600 hover:text-primary-500 dark:hover:text-primary-500 hover:bg-gray-50 dark:hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                  class="text-gray-700 dark:text-gray-300 hover:text-primary-500 dark:hover:text-primary-500 hover:bg-gray-50 dark:hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                >
                  <span
                    class="material-symbols-outlined text-gray-400 hover:text-primary-500 leading-6"
                  >
                    home
                  </span>
                  Dashboard
                </a>
              </li>
              <li *capRoleCheck="['OM', 'OA', 'SSA', 'SA']">
                <a
                  [routerLink]="['/management']"
                  routerLinkActive="bg-gray-50 dark:bg-gray-800 text-primary-600 dark:text-primary-600 hover:text-primary-500 dark:hover:text-primary-500 hover:bg-gray-50 dark:hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                  class="text-gray-700 dark:text-gray-300 hover:text-primary-500 dark:hover:text-primary-500 hover:bg-gray-50 dark:hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                >
                  <span
                    routerLinkActive="text-primary-600 hover:text-primary-500"
                    class="material-symbols-outlined text-gray-400 group-hover:text-primary-500 dark:group-hover:text-primary-500"
                  >
                    manage_accounts
                  </span>
                  Management
                </a>
              </li>
              <li *capRoleCheck="['SA', 'SSA']">
                <a
                  [routerLink]="['/administration']"
                  routerLinkActive="bg-gray-50 dark:bg-gray-800 text-primary-600 dark:text-primary-600 hover:text-primary-500 dark:hover:text-primary-500 hover:bg-gray-50 dark:hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                  class="text-gray-700 dark:text-gray-300 hover:text-primary-500 dark:hover:text-primary-500 hover:bg-gray-50 dark:hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                >
                  <span
                    routerLinkActive="text-primary-600 hover:text-primary-500"
                    class="material-symbols-outlined h-6 w-6 shrink-0 text-gray-400 group-hover:text-primary-500 dark:group-hover:text-primary-500"
                  >
                    admin_panel_settings
                  </span>
                  Administration
                </a>
              </li>
              <li>
                <a
                  *capRoleCheck="['OA', 'SSA', 'SA', 'I', 'OS']"
                  [routerLink]="['/assessments']"
                  routerLinkActive="bg-gray-50 dark:bg-gray-800 text-primary-600 dark:text-primary-600 hover:text-primary-500 dark:hover:text-primary-500 hover:bg-gray-50 dark:hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                  class="text-gray-700 dark:text-gray-300 hover:text-primary-500 dark:hover:text-primary-500 hover:bg-gray-50 dark:hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                >
                  <span
                    class="material-symbols-outlined text-gray-400 hover:text-primary-500"
                  >
                    checklist
                  </span>
                  Assessments
                </a>
              </li>
              <li>
                <a
                  [routerLink]="['/reports']"
                  *capRoleCheck="['OA', 'SSA', 'SA', 'I', 'OS']"
                  routerLinkActive="bg-gray-50 dark:bg-gray-800 text-primary-600 dark:text-primary-600 hover:text-primary-500 dark:hover:text-primary-500 hover:bg-gray-50 dark:hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                  class="text-gray-700 dark:text-gray-300 hover:text-primary-500 dark:hover:text-primary-500 hover:bg-gray-50 dark:hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                >
                  <span
                    class="material-symbols-outlined text-gray-400 hover:text-primary-500"
                  >
                    report
                  </span>
                  Reports
                </a>
              </li>
            </ul>
          </li>
          <li class="mt-auto">
            <a
              [routerLink]="['/settings']"
              routerLinkActive="bg-gray-50 dark:bg-gray-800 text-primary-600 dark:text-primary-600 hover:text-primary-500 dark:hover:text-primary-500 hover:bg-gray-50 dark:hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
              class="text-gray-700 dark:text-gray-300 hover:text-primary-500 dark:hover:text-primary-500 hover:bg-gray-50 dark:hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
            >
              <svg
                routerLinkActive="text-primary-600 hover:text-primary-500"
                class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-primary-500 dark:group-hover:text-primary-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
              Settings
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>

  <div class="lg:pl-56 bg-white dark:bg-gray-900">
    <div
      class="sticky top-0 z-40 lg:mx-auto lg:max-w-7xl lg:px-8 bg-white dark:bg-gray-900"
    >
      <div
        class="flex h-16 items-center gap-x-4 border-b border-gray-200 dark:border-gray-700 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-0 lg:shadow-none"
      >
        <button
          #openSidebarBtn
          (click)="openSidebar()"
          type="button"
          class="-m-2.5 p-2.5 text-gray-700 dark:text-gray-300 lg:hidden"
        >
          <span class="sr-only">Open sidebar</span>
          <svg
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </button>

        <!-- Separator -->
        <div
          class="h-6 w-px bg-gray-200 dark:bg-gray-700 lg:hidden"
          aria-hidden="true"
        ></div>

        <div class="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
          <form class="relative flex flex-1" action="#" method="GET"></form>
          <div class="flex items-center gap-x-4 lg:gap-x-6">
            <cap-theme></cap-theme>
            <button
              type="button"
              class="-m-2.5 p-2.5 text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
            >
              <span class="sr-only">View notifications</span>
              <svg
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
                />
              </svg>
            </button>

            <!-- Separator -->
            <div
              class="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200 dark:lg:bg-gray-700"
              aria-hidden="true"
            ></div>

            <!-- Profile dropdown -->
            <div class="relative" #profileDropdown>
              <button
                type="button"
                class="-m-1.5 flex items-center p-1.5"
                (click)="toggleDropdown()"
                id="user-menu-button"
                [attr.aria-expanded]="isDropdownOpen"
                aria-haspopup="true"
              >
                <span class="sr-only">Open user menu</span>
                @if (currentAvatar$ | async) {
                  <img
                    class="h-8 w-8 rounded-full bg-gray-50"
                    [src]="(currentAvatar$ | async)?.url"
                    alt="User Avatar"
                  />
                } @else {
                  <img
                    class="h-8 w-8 rounded-full bg-gray-50"
                    src="assets/images/default-avatar.jpeg"
                    alt="User Avatar"
                  />
                }

                <span class="hidden lg:flex lg:items-center">
                  <span
                    class="ml-4 text-sm font-semibold leading-6 text-gray-900 dark:text-gray-50"
                    aria-hidden="true"
                    >{{ (currentUser$ | async)?.firstName }}
                    {{ (currentUser$ | async)?.lastName }}</span
                  >
                  <svg
                    class="ml-2 h-5 w-5 text-gray-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </button>

              <!-- Dropdown menu -->
              @if (isDropdownOpen()) {
                <div
                  class="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white dark:bg-gray-800 py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  tabindex="-1"
                >
                  <a
                    routerLink="/settings/account"
                    class="block px-3 py-1 text-sm leading-6 text-center text-gray-900 dark:text-gray-50 hover:bg-gray-50 dark:hover:bg-gray-700"
                    role="menuitem"
                    tabindex="-1"
                    id="user-menu-item-0"
                    >Your Account</a
                  >
                  <button
                    type="button"
                    (click)="onLogout()"
                    class="block w-full px-3 py-1 text-sm leading-6 text-gray-900 dark:text-gray-50 hover:bg-gray-50 dark:hover:bg-gray-700"
                    role="menuitem"
                    tabindex="-1"
                    id="user-menu-item-1"
                  >
                    Sign out
                  </button>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>

    <main class="py-10">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <!-- Content -->
        <ng-content />
      </div>
    </main>
  </div>
</div>
